:host::ng-deep.ex5 .hex:hover {
    // background-color: black;
}

:host::ng-deep.hexinner {
    background-color: #fff;
    margin: 2px solid black;
}
:host::ng-deep.ex5 .hex.optionHex:hover .hexinner {
    /* We have created the hexagon via clip-path so we have to simulate
	a border using two nested elements. We can set the border colour
	using "background" and the border width using "margin" */
    // background: rgb(172, 158, 123) !important;
    // background: rgb(140, 165, 140) !important;
    color: black;
    margin: 5dpx;
    cursor: pointer;
    box-shadow: inset 0px 2px 28px #00000073;
}

// :host::ng-deep.currentHex .hexinner{
// 	background-color:yellow;
// }
:host::ng-deep.optionHex.selectedHex .hexinner {
    background-color: #fff;
    box-shadow: inset 0px 2px 28px #00000073;
}

.bgcolor {
    padding-top: 12px;
    // padding-bottom: 12px;
    background-color: rgb(96, 102, 208);
}

.body-container {
    width: 400px;
    position: relative;
}

.question {
    padding: 12px;
    background-color: white;
    color: #003c7b;
    border-radius: 10px;
    position: relative;
}
.option {
    text-align: center;
    // width: 60%;
    padding: 10px;
    background-color: rgb(252, 168, 46);
    color: white;
    border-radius: 10px;
    z-index: 1;
}

.optionRow {
    // margin: 10px;
    color: white;
}

.prevButton {
    z-index: 5;
}
.nextButton {
    z-index: 5;
}

h3.app_title {
    text-align: center;
    color: #fff;
    font-size: 35px;
    font-family: MTF Chubb, Fallback, sans-serif;
}
.question_title {
    position: absolute;
    top: -19px;
    left: 20px;
    background: #fca82e;
    color: #fff;
    padding: 4px 13px;
    border-radius: 10px;
    font-size: 12px;
}
.question {
    border: 5px solid #ffffff05;
    transition: border 0.225s;
    margin-top: 20px !important;
    padding-top: 20px !important;
    padding-bottom: 36px !important;

    .question-text {
        height: 88px;
        overflow: auto;
        font-size: 14px;
        line-height: 17px;
    }

    .pre-text {
        height: 35px;
        overflow: auto;
        font-size: 14px;
        line-height: 17px;
    }
}
.start_hexa {
    width: 50px;
    height: 46px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    position: absolute;
    top: -27px;
    right: 19px;
}
.start_hexa:after {
    width: 43px;
    height: 41px;
    background: #6066d0;
    content: "";
    position: absolute;
    z-index: -5;
    clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
}
.option_block {
    background: #fca82e;
    background-image: url(/assets/virus-free/ans_bg.png);
    width: 85%;
    margin: 0 auto;
    height: 90px;
    border-radius: 15px;
    background-size: 100% 100%;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 15px 15px 20px 15px;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
}

.option_block.quarantined {
    background-color: red !important;
    background-image: url(/assets/virus-free/ans_error_bg.png) !important;
}

.bottom_options {
    position: absolute;
    background: #fca82e;
    border-radius: 15px;
    background-image: url(/assets/virus-free/options_bg.png);
    background-size: cover;
    padding: 12px 0;
    width: calc(100% - 16px);
    bottom: 8px;
    // margin-top: 20px;

    min-height: 85px;
}
.bottom_options .options {
    width: 25%;
    display: inline-block;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.bottom_options .options img {
    display: block;
    margin: 0 auto;
}
.bottom_options .options span {
    font-size: 15px;
    margin-top: 5px;
    display: block;
}
.body_bg {
    transition: border 0.225s;
    // border: 5px solid #ffffff00;
    background: #fff;
    border-radius: 15px;
    padding: 7px 7px;
    position: relative;
    padding-top: 20px;
    margin-top: 20px;
    padding-bottom: 84px;
    min-height: 400px;
    max-height: 80vh;
    img {
        height: 100%;
    }

    background-repeat: no-repeat;
    background-origin: content-box;
}
.options_hexa {
    font-weight: 600;
    width: 60px;
    font-size: 12px;
    height: 52px;
    // background: #fff;
    // box-shadow: 4px 13px 3px #000 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ef9f2c;
    // -webkit-clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    // clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    position: relative;
    right: 15px;
}
.arrow_hexa_option {
    filter: drop-shadow(0px 0px 4px #00000014);
}
.hexa_arrow {
    position: absolute;
    right: 3%;
    bottom: 118px;
    width: 55px;
}
.opt_arrow {
    position: absolute;
}
.opt_arrow.opt1 {
    transform: rotate(-60deg);
    top: -5px;
    left: -26px;
}
.opt_arrow.opt2 {
    transform: rotate(0deg);
    top: -23px;
    left: 7px;
}
.opt_arrow.opt3 {
    transform: rotate(60deg);
    top: -4px;
    right: -2px;
}
.opt_arrow.opt4 {
    transform: rotate(-120deg);
    bottom: 0px;
    left: -26px;
}
.opt_arrow.opt5 {
    transform: rotate(120deg);
    bottom: 1px;
    right: -2px;
}
.opt_arrow.opt6 {
    transform: rotate(180deg);
    bottom: -18px;
    left: 7px;
}
// .body_bg:after {
//     content: "";
//     background-image: url(/assets/virus-free/Intersection1.png);
//     width: 55px;
//     height: 100px;
//     position: absolute;
//     top: 50px;
//     display: block;
//     z-index: 0;
//     left: 0;
//     background-size: contain;
//     background-repeat: no-repeat;
// }
// .body_bg:before {
//     content: "";
//     background-image: url(/assets/virus-free/Intersection2.png);
//     width: 55px;
//     height: 100px;
//     position: absolute;
//     bottom: 95px;
//     display: block;
//     z-index: 0;
//     right: -6px;
//     background-size: contain;
//     background-repeat: no-repeat;
// }
.arrow_img {
    position: absolute;
    clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    width: 50px;
    height: 46px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.direction_arrow {
    filter: drop-shadow(0px 3px 4px #0000004d);
    position: absolute;
    bottom: 24px;
    left: 20px;
}

.direction_arrow img {
    transition: 0.225s;
}

.direction_arrow .direction1 img {
    transform: rotate(0deg);
}
.direction_arrow .direction2 img {
    transform: rotate(60deg);
}
.direction_arrow .direction3 img {
    transform: rotate(120deg);
}
.direction_arrow .direction4 img {
    transform: rotate(180deg);
}
.direction_arrow .direction5 img {
    transform: rotate(240deg);
}
.direction_arrow .direction6 img {
    transform: rotate(300deg);
}
.hexa_map {
    margin: 0 auto;
    padding-bottom: 22px;
}

.currentHex .hexinner::before,
.path .hexinner::before {
    content: "";
    background: url("/assets/virus-free/path_arrow.png");
    width: 12px;
    height: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -4px;
    z-index: -1;
    visibility: hidden;
}
.currentHex.option1 .hexinner::before,
.path.option1 .hexinner::before {
    top: -2px;
    left: 18px;
    transform: rotate(0deg);
    visibility: visible;
}
.currentHex.option2 .hexinner::before,
.path.option2 .hexinner::before {
    top: 8px;
    right: 1px;
    transform: rotate(64deg);
    visibility: visible;
}
.currentHex.option3 .hexinner::before,
.path.option3 .hexinner::before {
    bottom: 6px;
    right: 1px;
    transform: rotate(121deg);
    visibility: visible;
}
.currentHex.option4 .hexinner::before,
.path.option4 .hexinner::before {
    bottom: -3px;
    left: 18px;
    transform: rotate(180deg);
    visibility: visible;
}
.currentHex.option5 .hexinner::before,
.path.option5 .hexinner::before { 
    bottom: 6px;
    left: 2px;
    transform: rotate(240deg);
    visibility: visible;
}
.currentHex.option6 .hexinner::before,
.path.option6 .hexinner::before {
    bottom: 28px;
    left: 2px;
    transform: rotate(300deg);
    visibility: visible;
}

// .danger_block .currentHex .hexinner {
//     background-image: url("/assets/virus-free/dangercell.png");
//     background-size: contain;
// }

.pop_heading {
    color: #fca82f;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 12px;
}
.pop_btext_sm {
    color: #003c7b;
    font-size: 13px;
    text-align: center;
}
.form_set label {
    display: block;
    line-height: initial;
    color: #003c7b;
    font-weight: 400;
    font-size: 15px;
}
.form_set input {
    width: 100%;
    border: 1px solid #ececec;
    padding: 6px 12px;
    border-radius: 35px;
    color: #003c7b;
}
.form_set {
    margin-bottom: 15px;
}
.or_divide {
    text-align: center;
    font-size: 23px;
    color: #fca82f;
    margin: 17px 0;
    position: relative;
}
.or_divide:after {
    content: "";
    width: 42%;
    background: #ddd;
    height: 2px;
    position: absolute;
    right: 0;
    top: 11px;
}
.or_divide:before {
    content: "";
    width: 42%;
    background: #ddd;
    height: 2px;
    position: absolute;
    left: 0;
    top: 11px;
}
.social_login ul {
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 25px;

    li img {
        width: 70px;
    }
}

.model_head {
    text-align: center;
    margin-bottom: 25px;
}
.model_button {
    text-align: center;
    margin-top: 30px;
}
button.pop_btn {
    background-color: #fca82f;
    color: #fff;
    border: none;
    border-radius: 30px;
    padding: 8px 15px;
    min-width: 120px;
    background-image: url(/assets/virus-free/pop_btn_bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.popup_dialog mat-dialog-container {
    background-image: url(/assets/virus-free/model_man.png), url(/assets/virus-free/model_botimg.png);
    background-repeat: no-repeat;
    background-position: 8% bottom, right bottom;
    border-radius: 15px !important;
    position: relative;
    overflow: inherit;
}
.model_close {
    position: absolute;
    top: -25px;
    z-index: 9999;
    right: 8px;
}
.share_fb {
    position: absolute;
    bottom: 9px;
    right: 0;
}
.hr {
    width: 100%;
    height: 2px;
    background: #ddd;
    margin-bottom: 20px;
}
.nocovid {
    position: absolute;
    top: -33px;
}
.popup_dialog .mat-dialog-container {
    padding-top: 35px;
}
.bible_vers_list ul {
    list-style: none;
    padding: 0;
}
.bible_vers_list ul li {
    text-align: center;
    color: #003c7b;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}
.bible_vers_list ul li:last-child {
    border-bottom: none;
}
.danger_block .start_hexa::after {
    background: #ff0000;
}
.danger_block .question {
    border: 5px solid red;
}
.danger_block .body_bg {
    border: 5px solid red;
}
.danger_icon {
    position: absolute;
}
.danger_icon img,
.success_icon img {
    width: 65px;
}
.danger_icon,
.success_icon {
    position: absolute;
    left: 18px;
    top: 60px;
    // left: 35px;
    // top: 80px;
}
.rem_days {
    position: absolute;
    left: 0;
    bottom: 0;
}
.rem_days img {
    width: 65px;
}
.maze {
    // display: flex;
    // position: relative;
    overflow-y: auto;
    height: 100%;
    // max-height: calc(494px - 116px);
    // height: calc(100% - 80px);
    padding-top: 24px;
    // align-items: center;

    background: #ffffffbf;
    border-radius: 8px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.maze.level-1 {
    padding-bottom: 18px !important;
}

.maze.level-2 {
    padding-top: 52px !important;
    padding-bottom: 24px !important;
}

.maze.level-3 {
    padding-top: 50px !important;
    padding-bottom: 90px !important;
}

.maze.level-4,
.maze.level-5,
.maze.level-6 {
    padding-top: 34px !important;
}

.rem_days .title {
    color: #2b5589;
    text-align: center;
    font-size: 11px;
    line-height: initial;
    font-weight: 600;
}
.rem_days span {
    position: absolute;
    // top: 45px;
    // top: 5vh;
    // left: 3vh;
    top: 37%;
    left: 28%;
    // left: 19px;
    min-width: 28px;
    font-size: 23px !important;
    margin-top: unset !important;
    font-weight: 600;
    color: #335c8d;
}
.rem_days {
    position: absolute;
    left: calc(50% - 32px);
    bottom: 66px;
}
.danger_block .hex.currentHex {
    background: red !important;
}
.profile_block {
    background: #fff;
    border-radius: 15px;
    position: relative;
    margin-top: 12px;

    box-shadow: 3px 3px 3px 3px #0000002b;
}
.prof_section .welcome {
    width: 60%;
    float: left;
}
.prof_section .avatar {
    width: 40%;
    float: left;
}
.prof_section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    min-height: 120px;
    padding-top: 10px;
}
.prof_section .welcome {
    width: 50%;
    float: left;
    color: #003c7b;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    text-shadow: 0px 0px 2px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0%;
    margin-bottom: 25px;
}
.prof_buttons .orange_btn {
    background: #fca82f;
    color: #fff;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    padding: 7px 14px;
    line-height: 21px;
    cursor: pointer;

    box-shadow: 0 8px 10px 0 rgb(0 0 0 / 24%), 0 12px 35px 0 rgb(0 0 0 / 19%);
}
.prof_buttons {
    position: absolute;
    bottom: -19px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
}
.prof_section .avatar img {
    width: 80px;
    position: relative;
    top: -6px;

    height: 80px;
    margin-left: 18px;
}
.page_title {
    text-align: center;
    color: #fff;
    font-size: 25px;
    text-shadow: 0px 0px 3px;
}
.leader_table th,
.leader_table td {
    text-align: center;
    padding: 4px 0;
}
.leader_table th {
    color: #003c7b;
}
.body_content {
    background: #fff;
    border-radius: 15px;
    padding: 30px 12px 12px;
    position: relative;
    margin-top: 35px;
}
.leader_table tr {
    border-bottom: 1px solid #d5d3d3;
}
.leader_table tr:first-child {
    border-bottom: 0px;
}
.ranking {
    position: relative;
    display: inline-block;
}
.ranking span {
    position: absolute;
    left: 0;
    right: 0;
    top: 2px;
    font-weight: 600;
    color: #003c7b;
}
.reg_save {
    text-align: center;
}
.leader_table tr.select td {
    color: #003c7b;
    font-weight: 600;
}
.drop_orange .mat-form-field-flex {
    background: #fca82f;
    padding: 7px 8px;
    border-radius: 40px;
}
.drop_orange .mat-form-field-infix {
    border-top: 0px;
    width: 100px;
    padding: 4px 0px 4px 9px;
}
.mat-select-arrow {
    color: rgb(255, 255, 255);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
}
.mat-select-value {
    color: #fff;
}
.level_drop {
    position: absolute;
    top: -20px;
    right: 15px;
}
.profile_drop {
    position: absolute;
    top: 8px;
    // right: 0;
    right: -40px;
}
.ls_title .level_title {
    display: table;
    float: left;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}
.ls_title .score_title {
    display: table;
    float: right;
    font-size: 20px;
    color: #fff;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 125px;
    text-align: center;
}
.ls_title {
    display: table;
    width: 100%;
}
.level_box {
    background: #fff;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    height: 55px;
    margin-bottom: 8px;
    gap: 2px;
    padding-right: 0px;

    cursor: pointer;
}
.score_box,
.score_section .raf_text {
    display: flex;
    align-items: center;
    color: #fff;
}
.score_box {
    overflow: hidden;
    background-color: #fca82f;
    background-image: url(/assets/virus-free/Intersection19.png);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    width: 30%;
    margin-top: -10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 22px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: -10px;
    // margin-right: -10px;
    margin-right: 0px;
}
.score_section .raf_text {
    color: white;
    background: #003c7b;
    position: absolute;
    right: 110px;
    width: 110px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 17px;
    border-radius: 15px 0 0 15px;
    font-size: 16px;
    font-weight: 500;
}
button.level_btn {
    border: none;
    padding: 7px 14px;
    color: #fff;
    border-radius: 30px;
    /* position: sticky; */
    bottom: -26px;
    left: 42px;
    /* min-width: 100px; */
    font-size: 14px;
    box-shadow: 0px 3px 6px #0000001f;
    margin-left: 5px;
}
button.level_btn.locked {
    background: #d1d1d1;
}
button.level_btn.active {
    background: #24e157;
}
button.level_btn.completed {
    background: #fca82f;
}
.level_box .level {
    position: relative;
    font-size: 14px;
    color: #003c7b;
    font-weight: 600;
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatars.popup_dialog mat-dialog-container {
    background-image: none;
}
.pop_avatar_select .options h2 {
    color: #082252;
    font-size: 18px;
}
.pop_avatar_select .options .avatar {
    text-align: center;
    display: flex;
    width: 150px;
    height: 150px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000036;
}
.options.selected .avatar {
    border: 3px solid #082252;
    position: relative;
}
.options.selected .avatar:after {
    content: "";
    background: url(/assets/virus-free/select.png);
    width: 25px;
    height: 25px;
    position: absolute;
    background-size: 100%;
    top: 13px;
    right: 3px;
}
.popup_dialog.plain_dialog .mat-dialog-container {
    background-image: none;
}
.score h5 {
    line-height: initial;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 3px;
}

.smily {
    position: absolute;
    right: 10px;
    bottom: 20px;
}
.avatar_change {
    text-align: center;
    display: flex;
    width: 125px;
    height: 125px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000036;
    position: absolute;
    top: -64px;
    left: 0;
    right: 0;
    background: #fca82f;
}
.edit_img {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50px;
    right: 5px;
    bottom: 17px;
    box-shadow: 0px 3px 6px #00000030;
}
.popup_dialog.profile_pop .mat-dialog-container {
    padding-top: 70px;
    padding-bottom: 50px;
    background-image: url(/assets/virus-free/model_botimg.png);
    background-position: right bottom;
}
.model_button.float_btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -17px;
}
.rules_block .rules {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 15px;
    position: relative;
    display: flex;
    min-height: 170px;
}
.rules_content {
    width: 60%;
    float: left;
    padding: 15px;
    color: #003c7b;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.rules_img {
    background: #fca82f;
    width: 40%;
    float: right;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.rules_btn button {
    border: none;
    min-width: 120px;
    font-size: 15px;
    color: #fff;
    padding: 7px 10px;
    border-radius: 50px;

    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.rules_btn {
    display: flex;
    justify-content: space-between;
}
.rules_btn .blue_btn {
    background: #003c7b;
    background-image: url(/assets/virus-free/btn_bg_blue.png) !important;
}
.rules_btn .orange_btn {
    background-image: url(/assets/virus-free/btn_bg_orange.png) !important;
    background: #fca82f;
}
@media (min-width: 425px) {
    .popup_dialog {
        min-width: 360px;
        width: 360px;
    }
}

input:focus,
button:focus,
a:focus {
    outline: none !important;
}

.loader-conatiner {
    .mat-spinner {
        margin: 0 auto;
    }
}

.hexinner {
    color: black !important;
}
.hex.selectedHex {
    background-color: #fca82f !important;
    z-index: 2 !important;

    .hexinner {
        margin: 3px;
        cursor: pointer;
    }
}

.bottom_options {
    .options.disabled {
        color: #ffffff7a;
        img {
            filter: opacity(50%);
        }
    }
    .options.quarantined {
        color: #ffffff7a;
        width: 50% !important;
    }
}

.primary-text {
    color: #003c7b;
}

.hex.hide {
    display: none;
}

.extra-buttons {
    display: flex;
    justify-content: space-evenly;
}

.body-container {
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.clickable,
.model_close {
    cursor: pointer;
}

.form-helper {
    color: grey;
    font-size: 11px;
    line-height: 14px !important;
    padding-left: 8px;
    padding-top: 4px;
}

.direction_arrow.dashboard-btn {
    bottom: unset;
    left: unset;
    top: 60px;
    right: 79px;
    z-index: 10;

    .arrow_img {
        width: 55px;
        height: 50px;
        background: #fca82f;
        color: white;
        .mat-icon-button {
            line-height: unset;
        }

        .mat-button-wrapper {
            font-size: 8px;
        }
    }
}

.success {
    .start_hexa:after {
        background: #24e157;
    }

    .currentHex {
        background: #24e157 !important;

        .hexinner {
            margin: 3px;
        }
    }
}

.score h1 {
    font-family: MTF Chubb, Fallback, sans-serif;
    font-size: 72px;
    line-height: 62px;
    color: #003c7b;
}

.currentHex .hexinner {
    background: #ffd9a3;
}

.play-icon.mat-icon {
    font-size: 46px;
    height: 46px;
    width: 46px;
}

.score_box .completed {
    display: flex;
    align-items: center;

    .restart-icon {
        height: 32px;
        width: 32px;
        font-size: 32px;
        margin-left: 0px;
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
}

.options.bible {
    position: relative;
}

.danger_block .bottom_options .options.bible img.bible-icon {
    animation: shadow-pulse 1s infinite;
}

.body-container {
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 426px) {
    .body-container {
        width: 100% !important;
    }
}

.route-container {
    // height: calc(100% - 44px);
    // overflow-y: auto;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 16px;
}

.ls_title {
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 12px;
}

.level_boards {
    position: relative;
    padding-top: 16px;
}

.leader_table {
    .highlight {
        color: #003c7b;
        font-weight: 600;
    }
}

.pop_content {
    padding-bottom: 24px;
}

.loader-container {
    display: flex;
    justify-content: center;
}

// .maze::-webkit-scrollbar {
//     display: none;
// }

@media (min-width: 427px) {
    .maze::-webkit-scrollbar,
    .route-container::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #ddd; /* or add it to the track */
        border-radius: 6px;
    }

    /* Add a thumb */
    .maze::-webkit-scrollbar-thumb,
    .route-container::-webkit-scrollbar-thumb {
        background: #aeaeae;
        border-radius: 6px;
    }
}

.model_close:focus {
    outline: none;
}

// #maze-body {
//     min-height: 494px;
//     height: fit-content;
// }

.app-progress-bar {
    position: absolute;
    z-index: 10;
}

.optionHex.selectedHex:not(.currentHex) {
    .hexinner {
        box-shadow: inset 5px 4px 12px #ffffff54;
    }
}

.hexinner {
    transition: 0.445s;
}

.hex:not(.selectedHex) {
    background: #84a1c3;
}

.hex[data-id="Goal1"],
.hex[data-id="Goal2"],
.hex[data-id="Goal3"],
.hex[data-id="Goal4"],
.hex[data-id="Goal5"],
.hex[data-id="Goal6"] {
    .hexcontent {
        position: relative;
        color: white;
        padding: 0.25em;
        font-size: 1.1em;
        text-align: center;
        line-height: 1.2em;
        font-family: "Hobo Std", sans-serif;
    }

    .hexcontent.currentGoal::before {
        background: unset;
        box-shadow: unset;
    }

    .hexcontent::before {
        content: " ";
        position: absolute;
        width: 24px;
        height: 24px;
        background: #fca82f;
        z-index: -1;
        box-shadow: 0 0 5px 4px #fca82f;
        border-radius: 50%;
        left: calc(50% - 12px);
        top: calc(50% - 12px);
    }
}

.mat-icon.img-icon {
    line-height: 12px !important;

    img {
        height: 100%;
    }
}

.loading {
    position: relative;
}
.loading::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";
    position: absolute;
    // width: 100%;
    // height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: loading 1.5s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.rules_img img {
    max-width: 80%;
}

.opt_arrow img {
    height: 20px;
}

.extra-buttons {
    margin-bottom: 70px;

    button {
        margin: 0 8px;
    }
}

.warning {
    height: 130px;
}

.admin-toolbar {
    background: #6066d0 !important;
}

.app-button:not(:disabled) {
    background: #6066d0;
}

.mat-card-header.with-action {
    justify-content: space-between;
}



.policy_popup  mat-dialog-container{
    max-width: 380px;
    border-radius: 15px !important;
    position: relative;
    overflow: inherit;
    height: 100%;
    

}

.person{
    position: absolute;
    bottom: 0px;
}

.bot{
   
    position: absolute;
    bottom: 0px;
   right: -5px;
}


::-webkit-scrollbar {
    width: 5px;
  }
  

  @media (min-width: 425px) {
    .policy_popup {
        min-width: 360px;
        width: 360px;
    }
}




/* Add application styles & imports to this file! */
@import url('https://fonts.googleapis.com/css?family=Spectral+SC:700');

@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';

.icon-btn {
    display: flex;
    align-items: center;

    .text {
        padding-left: 8px;
    }
}

@media (max-width: 360px) { 
    button.level_btn { 
        font-size: 11px;
    }
}