/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

@font-face {
  font-family: "MTF Chubb";
  src: url("assets/virus-free/fonts/MTF Chubb.ttf") format("truetype");
}
@font-face {
  font-family: "BeachmanScript";
  src: url("assets/font/BeachmanScript.ttf") format("truetype");
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #402001;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #6de354;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #402001;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #6de354;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #402001;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #6de354;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #402001;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #6de354;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #402001;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #6de354;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #402001;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #6de354;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #402001;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #6de354;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #402001;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #6de354;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #402001;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #6de354;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(64, 32, 1, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(64, 32, 1, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(64, 32, 1, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #402001;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(64, 32, 1, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(64, 32, 1, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(109, 227, 84, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(109, 227, 84, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(109, 227, 84, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #6de354;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(109, 227, 84, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(109, 227, 84, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #402001;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #6de354;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #402001;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #6de354;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #6de354;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #402001;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #6de354;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #402001;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #6de354;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #402001;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #6de354;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #402001;
}
.mat-icon.mat-accent {
  color: #6de354;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #402001;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #6de354;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #e0a05b;
}

.mat-progress-bar-buffer {
  background-color: #e0a05b;
}

.mat-progress-bar-fill::after {
  background-color: #402001;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d0fcc7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d0fcc7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #6de354;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #402001;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #6de354;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #402001;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #402001;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6de354;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #6de354;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #402001;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #6de354;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #6de354;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(109, 227, 84, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #6de354;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #402001;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(64, 32, 1, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #402001;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #402001;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(64, 32, 1, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #6de354;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(109, 227, 84, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #402001;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #6de354;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 160, 91, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #402001;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(208, 252, 199, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #6de354;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 160, 91, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #402001;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(208, 252, 199, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #6de354;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #402001;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #6de354;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #6de354;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  overscroll-behavior: contain;
}

body {
  margin: 0;
  font-family: Open Sans, "Helvetica Neue", sans-serif;
  overscroll-behavior: contain;
}

.account-container {
  background: url(/assets/images/bg.png);
  height: 100%;
  background-size: cover;
  background-position: center;
}

.user-app-container {
  background: url(/assets/images/light_bg.png);
  border-top: 12px solid #402001;
  background-attachment: fixed;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.user-app-container-alt {
  background: url(/assets/images/dark_bg.png);
  border-top: 12px solid #1c3c73;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.user-app-container-result {
  background: url(/assets/images/dark_bg.png);
  border-top: 12px solid #1c3c73;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.user-app-container-games {
  background: url(/assets/images/light_bg.png);
  height: 100%;
  border-top: 12px solid #402001;
  background-size: cover;
  background-position: center;
}

.user-app-container-resultss {
  height: 100%;
}

.user-app-containerss {
  height: 100%;
}

.user-app-module {
  height: 100%;
}

.sieve-question-selection-list .mat-list-item.mat-list-option {
  height: unset;
  margin-bottom: 14px;
  background-color: #f3f3f3;
  border-radius: 12px;
}
.sieve-question-selection-list .mat-list-item.mat-list-option.mat-list-single-selected-option {
  background-color: #795548;
  color: antiquewhite;
}
.sieve-question-selection-list .mat-list-item.mat-list-option .mat-list-item-content {
  padding: 12px 16px;
}

.games-module-container {
  height: 100%;
  /* You can add global styles to this file, and also import other style files */
}
.games-module-container .app-container {
  height: 100%;
  padding-top: 36px;
  background-image: url(/assets/images/app-bg.png);
}
.games-module-container .app-layout {
  height: 100%;
  position: relative;
}
.games-module-container .app-layout .app-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.games-module-container .app-layout .app-progress-bar .mat-progress-bar-fill::after {
  background-color: #795548;
}
.games-module-container .app-layout .logo-container {
  background: white;
  border: 2px solid #f5f5f5;
  border-radius: 63px;
  margin: 24px;
  margin-bottom: 0px;
  padding: 0px 8px;
}
.games-module-container .app-layout .logo-container img {
  width: 186px;
}
.games-module-container .app-layout .mat-toolbar {
  background-color: white;
  height: 90px;
}
.games-module-container .app-layout .mat-toolbar .logo img {
  height: 76px;
  margin: 0 38px;
}
.games-module-container .app-layout .mat-drawer-container {
  height: 100%;
}
.games-module-container .app-layout .mat-drawer-container .app-container {
  position: relative;
  min-height: 100%;
}
.games-module-container .app-layout .mat-drawer-container .body {
  padding-top: 16px;
  padding-bottom: 2.5rem;
}
.games-module-container .app-layout .mat-drawer-container .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer {
  min-width: 230px;
  background: #795548;
  z-index: 1;
  border: unset !important;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container {
  overflow-x: hidden;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container .mat-nav-list {
  margin-left: 36px !important;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container .mat-nav-list .sidenav-link {
  margin: 12px 0;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container .mat-nav-list .sidenav-link .sub-nav {
  margin-left: 0px !important;
  margin-right: 16px !important;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container .mat-nav-list .sidenav-link .sub-nav .sub-link {
  margin-top: 16px;
  border-radius: 24px;
  overflow: hidden;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container::-webkit-scrollbar {
  width: 0px;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container::-webkit-scrollbar-track {
  background: #2e3192;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-drawer-inner-container::-webkit-scrollbar-thumb:hover {
  background: #ababab;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-list-item {
  color: white;
  border-radius: 24px 0px 0px 24px;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-list-item .mat-ripple {
  border-radius: 24px 0px 0px 24px;
  display: none;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-list-item .mat-icon {
  margin-right: 12px;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-list-item.active {
  background: #fafafa;
  color: #795548 !important;
  position: relative;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-list-item.active::after {
  position: absolute;
  width: 36px;
  height: 36px;
  content: " ";
  z-index: -1;
  top: -36px;
  right: 0px;
  background: radial-gradient(200% 200% at top left, #136cb6 50%, #fafafa 50%);
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .mat-list-item.active::before {
  position: absolute;
  width: 36px;
  height: 36px;
  content: " ";
  z-index: -1;
  bottom: -36px;
  right: 0px;
  background: radial-gradient(200% 200% at bottom left, #136cb6 50%, #fafafa 50%);
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .sidenav-link .mat-expansion-panel {
  box-shadow: unset !important;
  background: transparent;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .sidenav-link .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0 0 0 24px;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .sidenav-link .mat-expansion-panel .mat-expansion-panel-body .mat-list-base {
  padding-top: 0px;
}
.games-module-container .app-layout .mat-drawer-container .mat-drawer .sidenav-link .mat-expansion-panel-spacing {
  margin: 0px 0;
}
.games-module-container .app-layout .top-nav-link {
  height: 100%;
  border-radius: 0px;
  display: flex;
  align-items: center;
  color: #505050;
}
.games-module-container .app-layout .top-nav-link img.avatar {
  border: 0.5px solid #80808042;
  height: 62px;
  border-radius: 50%;
  margin-left: 12px;
}
.games-module-container .seperator {
  flex: 1 1;
}
.games-module-container .stepper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}
.games-module-container .stepper .step-connector {
  height: 8px;
  background: #d8e8f6;
  flex: 1 1;
  margin: 0 -26px;
  margin-top: 28px;
}
.games-module-container .stepper .step-connector.active {
  background: #795548;
}
.games-module-container .stepper .step-connector:first-child {
  margin-left: unset !important;
  max-width: 26px !important;
  border-radius: 5px 0 0 5px;
  background: #795548;
}
.games-module-container .stepper .step-connector:last-child {
  margin-right: unset !important;
  max-width: 26px !important;
  border-radius: 0 5px 5px 0;
}
.games-module-container .stepper .step {
  text-align: center;
  color: #795548;
  padding: 0px 26px;
  cursor: pointer;
  width: 114px;
}
.games-module-container .stepper .step.error .stepper-icon-container {
  border-color: #ff4747d8 !important;
}
.games-module-container .stepper .step.error .stepper-icon-container .mat-icon {
  color: #ff4747d8 !important;
}
.games-module-container .stepper .step.active .stepper-icon-container {
  border: 4px solid #795548;
}
.games-module-container .stepper .step.completed .stepper-icon-container {
  background: #795548;
}
.games-module-container .stepper .step.completed .stepper-icon-container .icon .mat-icon {
  color: white !important;
}
.games-module-container .stepper .step .stepper-icon-container {
  padding: 16px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  background: #f2f9ff;
}
.games-module-container .stepper .step .stepper-icon-container .icon {
  line-height: 10px;
  width: 22px;
  color: #795548;
}
.games-module-container .stepper .step .stepper-icon-container .icon .mat-icon {
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: 22px;
}
.games-module-container .stepper .step .stepper-label {
  padding-top: 8px;
  font-size: 1rem;
  line-height: 1.2rem;
}
.games-module-container .card-outlined {
  background: #ffffff;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  padding: 16px;
}
.games-module-container .mat-card {
  box-shadow: 12px 12px 30px #00000012 !important;
  border-radius: 18px !important;
}
.games-module-container .search-form {
  position: relative;
}
.games-module-container .search-form .mat-icon.search-icon {
  position: absolute;
  right: 16px;
  top: 8px;
  color: #795548;
}
.games-module-container .form-control {
  border: 1px solid #795548;
  border-radius: 30px;
  width: 100%;
}
.games-module-container .form-control[readonly] {
  background-color: #fff;
}
.games-module-container .form-control[readonly]:focus {
  box-shadow: unset !important;
}
.games-module-container .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #795548;
  box-shadow: 0 0 0 0.2rem rgba(121, 85, 72, 0.25);
  outline: 0;
}
.games-module-container .form-control.search {
  border: 2px solid #795548;
  border-radius: 29px;
  padding-right: 46px;
  padding-left: 24px;
}
.games-module-container .form-control.search:focus {
  box-shadow: unset !important;
}
.games-module-container .form-controlinput, .games-module-container .form-control.mat-select {
  height: 38px;
}
.games-module-container .form-group {
  position: relative;
}
.games-module-container .form-group label {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: #795548;
  padding-left: 12px;
  margin-bottom: 0.1rem;
}
.games-module-container .form-group.verticle-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.games-module-container .form-group.verticle-align .form-control {
  max-width: 58px;
}
.games-module-container .form-group.with-suffix {
  position: relative;
}
.games-module-container .form-group.with-suffix .form-control {
  padding-right: 36px;
}
.games-module-container .form-group.with-suffix .suffix {
  color: #795548;
  position: absolute;
  right: 8px;
  bottom: 7px;
}
.games-module-container .form-group.with-prefix {
  position: relative;
}
.games-module-container .form-group.with-prefix .form-control {
  padding-right: 36px;
}
.games-module-container .form-group.with-prefix .prefix {
  color: #795548;
  position: absolute;
  left: 8px;
  bottom: 7px;
}
.games-module-container .form-group .form-control-group.contact-number {
  position: relative;
  padding-left: 104px;
}
.games-module-container .form-group .form-control-group.contact-number .country-code {
  position: absolute;
  width: 94px;
  left: 0;
  top: 0;
}
.games-module-container .form-group .mat-error {
  font-size: 10px;
  line-height: 13px;
  position: absolute;
  left: 12px;
  top: calc(100%);
}
.games-module-container .with-icon {
  position: relative;
}
.games-module-container .with-icon .form-control {
  padding-right: 20px;
}
.games-module-container .with-icon .suffix {
  color: #795548;
  position: absolute;
  right: 5px;
  bottom: 7px;
}
.games-module-container .with-error {
  position: relative;
  margin-bottom: 1rem;
}
.games-module-container .with-error .mat-error {
  font-size: 10px;
  line-height: 13px;
  position: absolute;
  left: 0;
  top: calc(100%);
}
.games-module-container .checkbox-outlined {
  border: 2px solid #dddfe1;
  border-radius: 6px;
  padding: 6px;
  display: flex;
  align-items: center;
  height: 42px !important;
}
.games-module-container .checkbox-outlined .mat-list-item-content {
  height: 40px !important;
  padding: 0 10px !important;
}
.games-module-container .checkbox-outlined .mat-icon {
  height: 18px;
  width: 18px;
  line-height: 18px;
  font-size: 18px;
  margin-right: 10px;
  margin-left: 4px;
  color: #795548;
}
.games-module-container .checkbox-outlined .text {
  white-space: nowrap;
  padding-left: 8px !important;
}
.games-module-container .checkbox-outlined .mat-list-text {
  flex-direction: row !important;
  align-items: center !important;
  padding-right: 8px !important;
}
.games-module-container .checkbox-outlined .mat-list-item-ripple {
  display: none;
}
.games-module-container .checkbox-outlined .mat-checkbox-inner-container {
  margin-left: 16px;
  margin-right: 4px;
}
.games-module-container .checkbox-outlined .mat-checkbox-layout {
  flex-direction: row-reverse !important;
  margin-bottom: 1px !important;
}
.games-module-container .mat-selection-list.row {
  display: flex !important;
}
.games-module-container .mat-checkbox .mat-checkbox-inner-container,
.games-module-container .mat-checkbox .mat-checkbox-frame,
.games-module-container .mat-checkbox .mat-checkbox-background {
  height: 20px;
  width: 20px;
}
.games-module-container .mat-checkbox.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #136cb6;
}
.games-module-container .mat-checkbox .mat-checkbox-frame {
  border: unset !important;
  background-color: #dfdfdf;
}
.games-module-container .mat-pseudo-checkbox {
  background-color: #dfdfdf !important;
  border: unset !important;
  height: 20px !important;
  width: 20px !important;
}
.games-module-container .mat-pseudo-checkbox-checked::after {
  top: 5.4px !important;
  left: 4px !important;
  width: 10px !important;
  height: 5px !important;
}
.games-module-container .mat-primary .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: #136cb6 !important;
}
.games-module-container .mat-primary .mat-pseudo-checkbox.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-checked::after {
  top: 5.4px !important;
  left: 4px !important;
  width: 10px !important;
  height: 5px !important;
}
.games-module-container .mat-accent .mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.games-module-container .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: #83c12e !important;
}
.games-module-container .mat-checkbox.invert .mat-checkbox-layout {
  flex-direction: row-reverse;
}
.games-module-container .mat-checkbox.invert .mat-checkbox-layout .mat-checkbox-inner-container {
  margin-right: 0px;
  margin-left: 8px;
}
.games-module-container .mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 231, 231, 0.54);
}
.games-module-container .mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(22px, 0, 0);
}
.games-module-container .mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #8bc34a;
}
.games-module-container .mat-slide-toggle .mat-slide-toggle-thumb-container {
  top: 1.5px;
  left: 3px;
}
.games-module-container .mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #8bc34a;
}
.games-module-container .mat-slide-toggle .mat-slide-toggle-bar {
  height: 26px;
  width: 50px;
  border-radius: 15px;
  background: white;
  border: 1px solid #a25833;
}
.games-module-container .slide-toggle-container {
  display: flex;
  align-items: center;
}
.games-module-container .slide-toggle-container label {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 17px;
  font-weight: 800;
}
.games-module-container .slide-toggle-container label:not(.selected) {
  color: #b9b9b9;
}
.games-module-container .slide-toggle-container input.form-control {
  width: 70px !important;
  text-align: center;
}
.games-module-container table {
  width: 100% !important;
}
.games-module-container table tr:focus {
  outline: unset !important;
}
.games-module-container .outlined-table th.mat-header-cell {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #136cb6;
  padding: 0 8px;
}
.games-module-container .outlined-table tr.mat-row {
  height: 66px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 2px;
  padding-right: 2px;
}
.games-module-container .outlined-table tr.mat-row.selectable {
  cursor: pointer;
}
.games-module-container .outlined-table tr.mat-row.selectable:hover .cell-content {
  border-color: #795548;
}
.games-module-container .outlined-table tr.mat-row .cell-content {
  background: #f7faff;
  height: 50px;
  display: flex;
  align-items: center;
  transition: border-color 155ms;
  border-bottom: 1px solid #f7faff;
  border-top: 1px solid #f7faff;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #000000cb;
  justify-content: center;
  padding: 0 8px;
}
.games-module-container .outlined-table td.mat-cell link {
  color: #ff000094;
}
.games-module-container .outlined-table td.mat-cell:first-of-type .cell-content {
  padding-left: 24px;
  border-left: 1px solid #f7faff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.games-module-container .outlined-table td.mat-cell:last-of-type .cell-content {
  padding-right: 24px;
  border-right: 1px solid #f7faff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.games-module-container .outlined-table td.mat-cell:first-of-type,
.games-module-container .outlined-table td.mat-cell:last-of-type {
  padding: unset;
}
.games-module-container .outlined-table th.mat-header-cell,
.games-module-container .outlined-table td.mat-cell,
.games-module-container .outlined-table td.mat-footer-cell {
  border: none;
  text-align: center;
}
.games-module-container .outlined-table th.mat-header-cell:focus,
.games-module-container .outlined-table td.mat-cell:focus,
.games-module-container .outlined-table td.mat-footer-cell:focus {
  outline: unset !important;
}
.games-module-container .question-column {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.games-module-container .table-no-data {
  padding-top: 36px;
}
.games-module-container button:focus {
  outline: none !important;
}
.games-module-container button.rounded {
  border-radius: 23px !important;
}
.games-module-container button.outline {
  border: 2px solid;
  overflow: hidden;
}
.games-module-container button.outline.mat-primary {
  border-color: #795548 !important;
  color: #795548 !important;
}
.games-module-container .mat-raised-button:not(.fit),
.games-module-container .mat-button:not(.fit) {
  min-width: 140px !important;
}
.games-module-container .mat-raised-button.mat-primary:not(:disabled) {
  background-color: #795548 !important;
}
.games-module-container .mat-raised-button.mat-primary:not(:disabled) .mat-button-wrapper {
  color: white !important;
}
.games-module-container .mat-flat-button.mat-accent,
.games-module-container .mat-raised-button.mat-accent,
.games-module-container .mat-fab.mat-accent,
.games-module-container .mat-mini-fab.mat-accent {
  background-color: #83c12e !important;
}
.games-module-container .mat-icon-button.social {
  height: 75px;
  width: 75px;
}
.games-module-container .mat-icon-button.social .mat-icon {
  height: 75px;
  width: 75px;
  line-height: 60px;
}
.games-module-container .mat-icon-button.social .mat-icon img {
  height: 75px;
  width: 75px;
}
.games-module-container .mat-mini-fab.direction-nav:not(:disabled) {
  background: #555454;
  color: white;
}
@media screen and (min-width: 320px) {
  .games-module-container .mat-raised-button:not(.fit),
.games-module-container .mat-button:not(.fit) {
    min-width: 120px !important;
  }
}
.games-module-container .mat-chip {
  color: white !important;
  background: #795548 !important;
}
.games-module-container .mat-chip .mat-icon.prefix-icon {
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-size: 16px;
  margin-right: 8px;
}
.games-module-container .mat-chip .mat-chip-remove {
  opacity: 0.9 !important;
  color: #ffffff !important;
}
.games-module-container .outlined-chip {
  border: 1px solid #dddfe1;
  border-radius: 19px;
  background: white !important;
  color: #795548 !important;
  padding: 7px 20px;
  font-size: 12px !important;
  line-height: 15px !important;
}
.games-module-container .projector-mode-options.mat-selection-list .mat-list-option {
  height: 116px;
  background-color: #764128;
  margin-bottom: 16px;
  box-shadow: inset 19px 19px 80px #532e1c, inset -19px -19px 80px #995534;
  border-radius: 25px;
  overflow: hidden;
  transition: 445ms;
}
.games-module-container .projector-mode-options.mat-selection-list .mat-list-option[aria-selected=true] {
  background-color: #83c12e;
  box-shadow: inset 24px 24px 78px #567f1e, inset -24px -24px 78px #b0ff3e;
}
.games-module-container .projector-mode-options.mat-selection-list .mat-list-option .mat-pseudo-checkbox {
  display: none;
}
.games-module-container .projector-mode-options.mat-selection-list .mat-list-option .mat-list-text {
  padding-right: 0px !important;
  text-align: center;
  color: white;
  font-family: "Bangers", cursive;
  font-size: 34px;
  line-height: 39px;
}
.games-module-container html,
.games-module-container body {
  height: 100%;
}
.games-module-container body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.games-module-container .sieve-app-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.games-module-container .primary-color {
  color: #795548;
}
.games-module-container .form-action {
  margin-top: 24px;
}
.games-module-container .loader-container .mat-spinner circle {
  stroke: #795548 !important;
}
.games-module-container .or-with-divider {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 18px;
  position: relative;
  color: #b9b9b9;
}
.games-module-container .social-action {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 0 32px 0;
}
.games-module-container .social-action .social {
  margin: 0 16px;
}
.games-module-container .slider-container {
  padding-top: 26px;
  margin-bottom: 12px;
  position: relative;
}
.games-module-container .slider-container .form-control {
  font-size: 15px;
  line-height: 20px;
  font-weight: 800;
  color: #795548;
}
.games-module-container .slider-container .mat-error {
  position: absolute;
  top: 100%;
  left: 12px;
}
.games-module-container .mat-slider {
  width: 100%;
}
.games-module-container .title > h3 {
  font-weight: 800;
  text-transform: uppercase;
}
.games-module-container .logo-container {
  padding: 16px;
  display: flex;
  justify-content: center;
}
.games-module-container .logo-container .logo-alt {
  width: 160px;
}
.games-module-container .subtitle {
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 8px;
  font-weight: bold;
}
.games-module-container .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.games-module-container .tutorial-btn {
  padding-top: 6px !important;
  line-height: 28px !important;
}
.games-module-container .tutorial-btn .mat-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.games-module-container button.back-button {
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 10;
}
.games-module-container .p-v-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.games-module-container .forgot-password-dialog {
  width: 350px;
}
.games-module-container .with-loader .mat-dialog-container {
  position: relative;
}
.games-module-container .with-loader .mat-dialog-container .mat-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.games-module-container h3.title-text {
  font-weight: bold;
}
.games-module-container .mat-standard-chip.mat-chip.sieve-chip {
  padding: 7px 12px 7px 4px;
  color: #794023 !important;
  background: #f0e7e3 !important;
  font-weight: 800;
  margin-right: 8px;
  margin-bottom: 8px;
}
.games-module-container .mat-standard-chip.mat-chip.my_preset {
  color: #215f60 !important;
  background: #baeac7 !important;
}
@media only screen and (min-width: 768px) {
  .games-module-container {
    /*
      create game 
    */
    /*
    Play area
    */
  }
  .games-module-container .create-game-container,
.games-module-container .lobby-container,
.games-module-container .play-area-container,
.games-module-container .responsive-container {
    height: 100%;
  }
  .games-module-container .create-game-container .action.desktop,
.games-module-container .lobby-container .action.desktop,
.games-module-container .play-area-container .action.desktop,
.games-module-container .responsive-container .action.desktop {
    display: block !important;
  }
  .games-module-container .create-game-container .header,
.games-module-container .lobby-container .header,
.games-module-container .play-area-container .header,
.games-module-container .responsive-container .header {
    display: flex;
    margin-bottom: 24px;
  }
  .games-module-container .create-game-container .header .title,
.games-module-container .lobby-container .header .title,
.games-module-container .play-area-container .header .title,
.games-module-container .responsive-container .header .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: unset !important;
  }
  .games-module-container .create-game-container .header .title > h3,
.games-module-container .lobby-container .header .title > h3,
.games-module-container .play-area-container .header .title > h3,
.games-module-container .responsive-container .header .title > h3 {
    margin: unset;
    font-size: 30px;
    line-height: 41px;
    font-weight: 800;
    color: #71422c;
    text-transform: uppercase;
  }
  .games-module-container .create-game-container .header .logo-container,
.games-module-container .create-game-container .header .title,
.games-module-container .lobby-container .header .logo-container,
.games-module-container .lobby-container .header .title,
.games-module-container .play-area-container .header .logo-container,
.games-module-container .play-area-container .header .title,
.games-module-container .responsive-container .header .logo-container,
.games-module-container .responsive-container .header .title {
    width: 33%;
  }
  .games-module-container .create-stepper-container .mat-horizontal-content-container {
    display: flex;
    height: 100%;
  }
  .games-module-container .create-stepper-container .mat-horizontal-content-container .mat-horizontal-stepper-content {
    width: 50%;
    transform: unset !important;
    visibility: visible !important;
    min-height: 100% !important;
    height: unset !important;
    padding: 0 18px;
  }
  .games-module-container .create-stepper-container .mat-horizontal-content-container .mat-horizontal-stepper-content:not(:last-child) {
    border-right: 2px solid #fbca7b;
  }
  .games-module-container .create-stepper-container .mat-horizontal-content-container .mat-horizontal-stepper-content .title,
.games-module-container .create-stepper-container .mat-horizontal-content-container .mat-horizontal-stepper-content .action {
    display: none;
  }
  .games-module-container .play-area-container .sieve-matrix {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
  }
  .games-module-container .play-area-container .question-container {
    width: 100%;
    max-width: 546px;
    margin: 0 auto;
  }
}

.notification-container .mat-dialog-container {
  border-radius: 15px !important;
  background: linear-gradient(181deg, #ded9d1 0%, #d7c095 100%);
  position: relative;
}

.account-container,
.user-app-container,
.user-app-container-alt,
.user-app-container-result,
.user-app-container-games {
  height: 100%;
}
.account-container .form-group,
.user-app-container .form-group,
.user-app-container-alt .form-group,
.user-app-container-result .form-group,
.user-app-container-games .form-group {
  position: relative;
}
.account-container .form-group label,
.user-app-container .form-group label,
.user-app-container-alt .form-group label,
.user-app-container-result .form-group label,
.user-app-container-games .form-group label {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: white;
  padding-left: 12px;
  margin-bottom: 0.1rem;
}
.account-container .form-group .form-control,
.user-app-container .form-group .form-control,
.user-app-container-alt .form-group .form-control,
.user-app-container-result .form-group .form-control,
.user-app-container-games .form-group .form-control {
  border-radius: 40px;
}
.account-container .form-group .form-control:focus,
.user-app-container .form-group .form-control:focus,
.user-app-container-alt .form-group .form-control:focus,
.user-app-container-result .form-group .form-control:focus,
.user-app-container-games .form-group .form-control:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}
.account-container .form-group .mat-hint,
.user-app-container .form-group .mat-hint,
.user-app-container-alt .form-group .mat-hint,
.user-app-container-result .form-group .mat-hint,
.user-app-container-games .form-group .mat-hint {
  color: white;
  padding-left: 16px;
}
.account-container .form-group.verticle-align,
.user-app-container .form-group.verticle-align,
.user-app-container-alt .form-group.verticle-align,
.user-app-container-result .form-group.verticle-align,
.user-app-container-games .form-group.verticle-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account-container .form-group.verticle-align .form-control,
.user-app-container .form-group.verticle-align .form-control,
.user-app-container-alt .form-group.verticle-align .form-control,
.user-app-container-result .form-group.verticle-align .form-control,
.user-app-container-games .form-group.verticle-align .form-control {
  max-width: 58px;
}
.account-container .form-group.with-suffix,
.user-app-container .form-group.with-suffix,
.user-app-container-alt .form-group.with-suffix,
.user-app-container-result .form-group.with-suffix,
.user-app-container-games .form-group.with-suffix {
  position: relative;
}
.account-container .form-group.with-suffix.toggle.active .form-control,
.user-app-container .form-group.with-suffix.toggle.active .form-control,
.user-app-container-alt .form-group.with-suffix.toggle.active .form-control,
.user-app-container-result .form-group.with-suffix.toggle.active .form-control,
.user-app-container-games .form-group.with-suffix.toggle.active .form-control {
  color: #6de354 !important;
}
.account-container .form-group.with-suffix.toggle.active .suffix,
.user-app-container .form-group.with-suffix.toggle.active .suffix,
.user-app-container-alt .form-group.with-suffix.toggle.active .suffix,
.user-app-container-result .form-group.with-suffix.toggle.active .suffix,
.user-app-container-games .form-group.with-suffix.toggle.active .suffix {
  background-color: #6de354;
}
.account-container .form-group.with-suffix.toggle .suffix,
.user-app-container .form-group.with-suffix.toggle .suffix,
.user-app-container-alt .form-group.with-suffix.toggle .suffix,
.user-app-container-result .form-group.with-suffix.toggle .suffix,
.user-app-container-games .form-group.with-suffix.toggle .suffix {
  color: white;
  background-color: #484848;
  border-radius: 50%;
  padding: 3px;
  font-size: 18px;
}
.account-container .form-group.with-suffix .form-control,
.user-app-container .form-group.with-suffix .form-control,
.user-app-container-alt .form-group.with-suffix .form-control,
.user-app-container-result .form-group.with-suffix .form-control,
.user-app-container-games .form-group.with-suffix .form-control {
  padding-right: 36px;
}
.account-container .form-group.with-suffix .suffix,
.user-app-container .form-group.with-suffix .suffix,
.user-app-container-alt .form-group.with-suffix .suffix,
.user-app-container-result .form-group.with-suffix .suffix,
.user-app-container-games .form-group.with-suffix .suffix {
  color: #f2b40b;
  position: absolute;
  right: 8px;
  bottom: 7px;
}
.account-container .form-group.with-prefix,
.user-app-container .form-group.with-prefix,
.user-app-container-alt .form-group.with-prefix,
.user-app-container-result .form-group.with-prefix,
.user-app-container-games .form-group.with-prefix {
  position: relative;
}
.account-container .form-group.with-prefix .form-control,
.user-app-container .form-group.with-prefix .form-control,
.user-app-container-alt .form-group.with-prefix .form-control,
.user-app-container-result .form-group.with-prefix .form-control,
.user-app-container-games .form-group.with-prefix .form-control {
  padding-right: 36px;
}
.account-container .form-group.with-prefix .prefix,
.user-app-container .form-group.with-prefix .prefix,
.user-app-container-alt .form-group.with-prefix .prefix,
.user-app-container-result .form-group.with-prefix .prefix,
.user-app-container-games .form-group.with-prefix .prefix {
  color: #f2b40b;
  position: absolute;
  left: 8px;
  bottom: 7px;
}
.account-container .form-group .form-control-group.contact-number,
.user-app-container .form-group .form-control-group.contact-number,
.user-app-container-alt .form-group .form-control-group.contact-number,
.user-app-container-result .form-group .form-control-group.contact-number,
.user-app-container-games .form-group .form-control-group.contact-number {
  position: relative;
  padding-left: 104px;
}
.account-container .form-group .form-control-group.contact-number .country-code,
.user-app-container .form-group .form-control-group.contact-number .country-code,
.user-app-container-alt .form-group .form-control-group.contact-number .country-code,
.user-app-container-result .form-group .form-control-group.contact-number .country-code,
.user-app-container-games .form-group .form-control-group.contact-number .country-code {
  position: absolute;
  width: 94px;
  left: 0;
  top: 0;
}
.account-container .form-group .mat-error,
.user-app-container .form-group .mat-error,
.user-app-container-alt .form-group .mat-error,
.user-app-container-result .form-group .mat-error,
.user-app-container-games .form-group .mat-error {
  font-size: 10px;
  line-height: 13px;
  position: absolute;
  left: 12px;
  top: calc(100%);
}
.account-container .with-icon,
.user-app-container .with-icon,
.user-app-container-alt .with-icon,
.user-app-container-result .with-icon,
.user-app-container-games .with-icon {
  position: relative;
}
.account-container .with-icon .form-control,
.user-app-container .with-icon .form-control,
.user-app-container-alt .with-icon .form-control,
.user-app-container-result .with-icon .form-control,
.user-app-container-games .with-icon .form-control {
  padding-right: 20px;
}
.account-container .with-icon .suffix,
.user-app-container .with-icon .suffix,
.user-app-container-alt .with-icon .suffix,
.user-app-container-result .with-icon .suffix,
.user-app-container-games .with-icon .suffix {
  color: #f2b40b;
  position: absolute;
  right: 5px;
  bottom: 7px;
}
.account-container .with-error,
.user-app-container .with-error,
.user-app-container-alt .with-error,
.user-app-container-result .with-error,
.user-app-container-games .with-error {
  position: relative;
  margin-bottom: 1rem;
}
.account-container .with-error .mat-error,
.user-app-container .with-error .mat-error,
.user-app-container-alt .with-error .mat-error,
.user-app-container-result .with-error .mat-error,
.user-app-container-games .with-error .mat-error {
  font-size: 10px;
  line-height: 13px;
  position: absolute;
  left: 0;
  top: calc(100%);
}
.account-container button:focus,
.user-app-container button:focus,
.user-app-container-alt button:focus,
.user-app-container-result button:focus,
.user-app-container-games button:focus {
  outline: none !important;
}
.account-container button.rounded,
.user-app-container button.rounded,
.user-app-container-alt button.rounded,
.user-app-container-result button.rounded,
.user-app-container-games button.rounded {
  border-radius: 23px !important;
  padding: 0 24px;
}
.account-container button.rounded.loading,
.user-app-container button.rounded.loading,
.user-app-container-alt button.rounded.loading,
.user-app-container-result button.rounded.loading,
.user-app-container-games button.rounded.loading {
  padding-right: 36px !important;
}
.account-container button.outline,
.user-app-container button.outline,
.user-app-container-alt button.outline,
.user-app-container-result button.outline,
.user-app-container-games button.outline {
  border: 2px solid;
  overflow: hidden;
}
.account-container button.outline.mat-primary,
.user-app-container button.outline.mat-primary,
.user-app-container-alt button.outline.mat-primary,
.user-app-container-result button.outline.mat-primary,
.user-app-container-games button.outline.mat-primary {
  border-color: #f2b40b;
  color: #f2b40b;
}
.account-container .mat-raised-button:not(.fit),
.account-container .mat-button:not(.fit),
.user-app-container .mat-raised-button:not(.fit),
.user-app-container .mat-button:not(.fit),
.user-app-container-alt .mat-raised-button:not(.fit),
.user-app-container-alt .mat-button:not(.fit),
.user-app-container-result .mat-raised-button:not(.fit),
.user-app-container-result .mat-button:not(.fit),
.user-app-container-games .mat-raised-button:not(.fit),
.user-app-container-games .mat-button:not(.fit) {
  min-width: 140px !important;
}
.account-container .mat-raised-button.mat-primary:not(:disabled),
.user-app-container .mat-raised-button.mat-primary:not(:disabled),
.user-app-container-alt .mat-raised-button.mat-primary:not(:disabled),
.user-app-container-result .mat-raised-button.mat-primary:not(:disabled),
.user-app-container-games .mat-raised-button.mat-primary:not(:disabled) {
  background-color: #f2b40b;
}
.account-container .mat-raised-button.mat-primary:not(:disabled) .mat-button-wrapper,
.user-app-container .mat-raised-button.mat-primary:not(:disabled) .mat-button-wrapper,
.user-app-container-alt .mat-raised-button.mat-primary:not(:disabled) .mat-button-wrapper,
.user-app-container-result .mat-raised-button.mat-primary:not(:disabled) .mat-button-wrapper,
.user-app-container-games .mat-raised-button.mat-primary:not(:disabled) .mat-button-wrapper {
  color: #3b1d00;
}
.account-container .button-loader,
.user-app-container .button-loader,
.user-app-container-alt .button-loader,
.user-app-container-result .button-loader,
.user-app-container-games .button-loader {
  position: absolute !important;
  right: 5px;
  top: 7px;
}
.account-container .mat-icon-button.social .mat-icon,
.user-app-container .mat-icon-button.social .mat-icon,
.user-app-container-alt .mat-icon-button.social .mat-icon,
.user-app-container-result .mat-icon-button.social .mat-icon,
.user-app-container-games .mat-icon-button.social .mat-icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
@media screen and (min-width: 320px) {
  .account-container .mat-raised-button:not(.fit),
.account-container .mat-button:not(.fit),
.user-app-container .mat-raised-button:not(.fit),
.user-app-container .mat-button:not(.fit),
.user-app-container-alt .mat-raised-button:not(.fit),
.user-app-container-alt .mat-button:not(.fit),
.user-app-container-result .mat-raised-button:not(.fit),
.user-app-container-result .mat-button:not(.fit),
.user-app-container-games .mat-raised-button:not(.fit),
.user-app-container-games .mat-button:not(.fit) {
    min-width: 120px !important;
  }
}
.account-container .flex-separator,
.user-app-container .flex-separator,
.user-app-container-alt .flex-separator,
.user-app-container-result .flex-separator,
.user-app-container-games .flex-separator {
  flex: 1 1 auto;
}
.account-container section.page-header,
.user-app-container section.page-header,
.user-app-container-alt section.page-header,
.user-app-container-result section.page-header,
.user-app-container-games section.page-header {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account-container section.page-header .page-title.count,
.user-app-container section.page-header .page-title.count,
.user-app-container-alt section.page-header .page-title.count,
.user-app-container-result section.page-header .page-title.count,
.user-app-container-games section.page-header .page-title.count {
  position: relative;
}
.account-container section.page-header .page-title.count .mat-icon,
.user-app-container section.page-header .page-title.count .mat-icon,
.user-app-container-alt section.page-header .page-title.count .mat-icon,
.user-app-container-result section.page-header .page-title.count .mat-icon,
.user-app-container-games section.page-header .page-title.count .mat-icon {
  height: 50px;
  width: 50px;
}
.account-container section.page-header .page-title.count .brain_count,
.user-app-container section.page-header .page-title.count .brain_count,
.user-app-container-alt section.page-header .page-title.count .brain_count,
.user-app-container-result section.page-header .page-title.count .brain_count,
.user-app-container-games section.page-header .page-title.count .brain_count {
  position: absolute;
  left: 7px;
  font-size: 15px;
}
.account-container section.page-header .page-title,
.user-app-container section.page-header .page-title,
.user-app-container-alt section.page-header .page-title,
.user-app-container-result section.page-header .page-title,
.user-app-container-games section.page-header .page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 16px;
  font-weight: 500;
}
.account-container section.page-header .page-title .mat-icon,
.user-app-container section.page-header .page-title .mat-icon,
.user-app-container-alt section.page-header .page-title .mat-icon,
.user-app-container-result section.page-header .page-title .mat-icon,
.user-app-container-games section.page-header .page-title .mat-icon {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 36px;
}
.account-container section.page-header .page-title .text,
.user-app-container section.page-header .page-title .text,
.user-app-container-alt section.page-header .page-title .text,
.user-app-container-result section.page-header .page-title .text,
.user-app-container-games section.page-header .page-title .text {
  padding-left: 8px;
  color: #402001;
}
.account-container .loader-container,
.user-app-container .loader-container,
.user-app-container-alt .loader-container,
.user-app-container-result .loader-container,
.user-app-container-games .loader-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-container .loader-container .mat-spinner,
.user-app-container .loader-container .mat-spinner,
.user-app-container-alt .loader-container .mat-spinner,
.user-app-container-result .loader-container .mat-spinner,
.user-app-container-games .loader-container .mat-spinner {
  margin: 0 auto;
  margin-bottom: 24px;
}
.account-container .loader-container .mat-spinner circle,
.user-app-container .loader-container .mat-spinner circle,
.user-app-container-alt .loader-container .mat-spinner circle,
.user-app-container-result .loader-container .mat-spinner circle,
.user-app-container-games .loader-container .mat-spinner circle {
  stroke: white;
}
.account-container .loader-container .loader-text,
.user-app-container .loader-container .loader-text,
.user-app-container-alt .loader-container .loader-text,
.user-app-container-result .loader-container .loader-text,
.user-app-container-games .loader-container .loader-text {
  font-size: 15px;
  line-height: 19px;
  color: white;
  font-weight: 500;
  text-align: center;
}
.account-container .app-dialog.cdk-overlay-pane,
.user-app-container .app-dialog.cdk-overlay-pane,
.user-app-container-alt .app-dialog.cdk-overlay-pane,
.user-app-container-result .app-dialog.cdk-overlay-pane,
.user-app-container-games .app-dialog.cdk-overlay-pane {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.account-container .app-dialog.cdk-overlay-pane .mat-dialog-container,
.user-app-container .app-dialog.cdk-overlay-pane .mat-dialog-container,
.user-app-container-alt .app-dialog.cdk-overlay-pane .mat-dialog-container,
.user-app-container-result .app-dialog.cdk-overlay-pane .mat-dialog-container,
.user-app-container-games .app-dialog.cdk-overlay-pane .mat-dialog-container {
  border-radius: unset !important;
  background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
  position: relative;
}
.account-container .app-dialog.cdk-overlay-pane .mat-dialog-container button.close,
.user-app-container .app-dialog.cdk-overlay-pane .mat-dialog-container button.close,
.user-app-container-alt .app-dialog.cdk-overlay-pane .mat-dialog-container button.close,
.user-app-container-result .app-dialog.cdk-overlay-pane .mat-dialog-container button.close,
.user-app-container-games .app-dialog.cdk-overlay-pane .mat-dialog-container button.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: white;
}
.account-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title,
.user-app-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title,
.user-app-container-alt .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title,
.user-app-container-result .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title,
.user-app-container-games .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text,
.user-app-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text,
.user-app-container-alt .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text,
.user-app-container-result .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text,
.user-app-container-games .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
  margin-left: 12px;
  border-bottom: 1px solid #1b3b73;
}
.account-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon,
.user-app-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon,
.user-app-container-alt .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon,
.user-app-container-result .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon,
.user-app-container-games .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 38px;
}
.account-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content,
.user-app-container .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content,
.user-app-container-alt .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content,
.user-app-container-result .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content,
.user-app-container-games .app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  overflow: auto;
}
.account-container .tab_knowledge .mat-tab-label,
.user-app-container .tab_knowledge .mat-tab-label,
.user-app-container-alt .tab_knowledge .mat-tab-label,
.user-app-container-result .tab_knowledge .mat-tab-label,
.user-app-container-games .tab_knowledge .mat-tab-label {
  background: white;
  opacity: 1;
  width: 33.3%;
  clip-path: polygon(85% 0%, 100% 100%, 71% 100%, 0 100%, 15% 0%);
  height: 38px;
}
.account-container .tab_knowledge .mat-tab-label-active,
.user-app-container .tab_knowledge .mat-tab-label-active,
.user-app-container-alt .tab_knowledge .mat-tab-label-active,
.user-app-container-result .tab_knowledge .mat-tab-label-active,
.user-app-container-games .tab_knowledge .mat-tab-label-active {
  background: #6c431c !important;
  color: #fff;
}
.account-container .tab_knowledge mat-ink-bar.mat-ink-bar,
.user-app-container .tab_knowledge mat-ink-bar.mat-ink-bar,
.user-app-container-alt .tab_knowledge mat-ink-bar.mat-ink-bar,
.user-app-container-result .tab_knowledge mat-ink-bar.mat-ink-bar,
.user-app-container-games .tab_knowledge mat-ink-bar.mat-ink-bar {
  display: none;
}
.account-container .tab_knowledge .mat-tab-list,
.user-app-container .tab_knowledge .mat-tab-list,
.user-app-container-alt .tab_knowledge .mat-tab-list,
.user-app-container-result .tab_knowledge .mat-tab-list,
.user-app-container-games .tab_knowledge .mat-tab-list {
  border-bottom: 3px solid #6c431c;
}
.account-container .chart_bg,
.user-app-container .chart_bg,
.user-app-container-alt .chart_bg,
.user-app-container-result .chart_bg,
.user-app-container-games .chart_bg {
  background: #fff;
  border-radius: 25px;
  padding: 15px 20px;
  margin-top: 20px;
}
.account-container .table_wbg,
.user-app-container .table_wbg,
.user-app-container-alt .table_wbg,
.user-app-container-result .table_wbg,
.user-app-container-games .table_wbg {
  background: #fff;
  border-radius: 25px;
  padding: 10px;
}
.account-container .table_wbg .table td.result-container img,
.user-app-container .table_wbg .table td.result-container img,
.user-app-container-alt .table_wbg .table td.result-container img,
.user-app-container-result .table_wbg .table td.result-container img,
.user-app-container-games .table_wbg .table td.result-container img {
  float: left;
}
.account-container .table_wbg .table th,
.user-app-container .table_wbg .table th,
.user-app-container-alt .table_wbg .table th,
.user-app-container-result .table_wbg .table th,
.user-app-container-games .table_wbg .table th {
  color: #6c431c;
  font-size: 13px;
  padding: 5px !important;
  border-top: none;
}
.account-container .table_wbg .table td,
.user-app-container .table_wbg .table td,
.user-app-container-alt .table_wbg .table td,
.user-app-container-result .table_wbg .table td,
.user-app-container-games .table_wbg .table td {
  padding: 5px !important;
  font-size: 12px;
}
.account-container .table_wbg .result-img,
.user-app-container .table_wbg .result-img,
.user-app-container-alt .table_wbg .result-img,
.user-app-container-result .table_wbg .result-img,
.user-app-container-games .table_wbg .result-img {
  text-align: center;
}
.account-container .table_wbg .result-img img,
.user-app-container .table_wbg .result-img img,
.user-app-container-alt .table_wbg .result-img img,
.user-app-container-result .table_wbg .result-img img,
.user-app-container-games .table_wbg .result-img img {
  width: 36px;
  margin: 0 auto !important;
}
.account-container .custom_scroll::-webkit-scrollbar,
.user-app-container .custom_scroll::-webkit-scrollbar,
.user-app-container-alt .custom_scroll::-webkit-scrollbar,
.user-app-container-result .custom_scroll::-webkit-scrollbar,
.user-app-container-games .custom_scroll::-webkit-scrollbar {
  width: 4px;
}
.account-container .custom_scroll::-webkit-scrollbar-track,
.user-app-container .custom_scroll::-webkit-scrollbar-track,
.user-app-container-alt .custom_scroll::-webkit-scrollbar-track,
.user-app-container-result .custom_scroll::-webkit-scrollbar-track,
.user-app-container-games .custom_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.account-container .custom_scroll::-webkit-scrollbar-thumb,
.user-app-container .custom_scroll::-webkit-scrollbar-thumb,
.user-app-container-alt .custom_scroll::-webkit-scrollbar-thumb,
.user-app-container-result .custom_scroll::-webkit-scrollbar-thumb,
.user-app-container-games .custom_scroll::-webkit-scrollbar-thumb {
  background-color: #6c431c;
  outline: 1px solid #6c431c;
}
.account-container .form_date_inputs .date-range-input,
.user-app-container .form_date_inputs .date-range-input,
.user-app-container-alt .form_date_inputs .date-range-input,
.user-app-container-result .form_date_inputs .date-range-input,
.user-app-container-games .form_date_inputs .date-range-input {
  border: none !important;
  padding: 0;
  position: relative;
}
.account-container .form_date_inputs input,
.user-app-container .form_date_inputs input,
.user-app-container-alt .form_date_inputs input,
.user-app-container-result .form_date_inputs input,
.user-app-container-games .form_date_inputs input {
  padding: 8px 5px;
  width: 100px;
  border: none;
  border-radius: 50px;
}
.account-container .form_date_inputs mat-datepicker-toggle,
.user-app-container .form_date_inputs mat-datepicker-toggle,
.user-app-container-alt .form_date_inputs mat-datepicker-toggle,
.user-app-container-result .form_date_inputs mat-datepicker-toggle,
.user-app-container-games .form_date_inputs mat-datepicker-toggle {
  position: absolute;
  right: 0;
}
.account-container .form_date_inputs mat-datepicker-toggle svg,
.user-app-container .form_date_inputs mat-datepicker-toggle svg,
.user-app-container-alt .form_date_inputs mat-datepicker-toggle svg,
.user-app-container-result .form_date_inputs mat-datepicker-toggle svg,
.user-app-container-games .form_date_inputs mat-datepicker-toggle svg {
  width: 15px;
  color: #6c431c;
}
.account-container .biblist-filter-head label,
.user-app-container .biblist-filter-head label,
.user-app-container-alt .biblist-filter-head label,
.user-app-container-result .biblist-filter-head label,
.user-app-container-games .biblist-filter-head label {
  color: #6c431c;
  font-weight: 500;
  font-size: 11px;
  display: block;
}
.account-container .form_level_inputs,
.user-app-container .form_level_inputs,
.user-app-container-alt .form_level_inputs,
.user-app-container-result .form_level_inputs,
.user-app-container-games .form_level_inputs {
  padding-left: 7px;
}
.account-container .form_level_inputs mat-select,
.user-app-container .form_level_inputs mat-select,
.user-app-container-alt .form_level_inputs mat-select,
.user-app-container-result .form_level_inputs mat-select,
.user-app-container-games .form_level_inputs mat-select {
  font-size: 12px;
  padding: 6px 14px;
  width: 90px;
}
.account-container .biblist-sub-filter label,
.user-app-container .biblist-sub-filter label,
.user-app-container-alt .biblist-sub-filter label,
.user-app-container-result .biblist-sub-filter label,
.user-app-container-games .biblist-sub-filter label {
  color: #6c431c;
  font-weight: 500;
  font-size: 11px;
}
.account-container .biblist-sub-filter .col-md-3,
.user-app-container .biblist-sub-filter .col-md-3,
.user-app-container-alt .biblist-sub-filter .col-md-3,
.user-app-container-result .biblist-sub-filter .col-md-3,
.user-app-container-games .biblist-sub-filter .col-md-3 {
  padding: 0 5px !important;
}
.account-container .biblist-sub-filter .mat-select,
.user-app-container .biblist-sub-filter .mat-select,
.user-app-container-alt .biblist-sub-filter .mat-select,
.user-app-container-result .biblist-sub-filter .mat-select,
.user-app-container-games .biblist-sub-filter .mat-select {
  font-size: 12px;
}
.account-container .white_bg,
.user-app-container .white_bg,
.user-app-container-alt .white_bg,
.user-app-container-result .white_bg,
.user-app-container-games .white_bg {
  background: #fff;
  padding: 15px;
  border-radius: 20px;
}
.account-container .analysis_bot_tot,
.user-app-container .analysis_bot_tot,
.user-app-container-alt .analysis_bot_tot,
.user-app-container-result .analysis_bot_tot,
.user-app-container-games .analysis_bot_tot {
  align-items: center;
  margin: 3px 0;
}
.account-container .analysis_bot_tot .bt_img,
.user-app-container .analysis_bot_tot .bt_img,
.user-app-container-alt .analysis_bot_tot .bt_img,
.user-app-container-result .analysis_bot_tot .bt_img,
.user-app-container-games .analysis_bot_tot .bt_img {
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.account-container .analysis_bot_tot .bt_totals,
.user-app-container .analysis_bot_tot .bt_totals,
.user-app-container-alt .analysis_bot_tot .bt_totals,
.user-app-container-result .analysis_bot_tot .bt_totals,
.user-app-container-games .analysis_bot_tot .bt_totals {
  width: 93%;
}
.account-container .analysis_bot_tot .bt_totals .tot,
.user-app-container .analysis_bot_tot .bt_totals .tot,
.user-app-container-alt .analysis_bot_tot .bt_totals .tot,
.user-app-container-result .analysis_bot_tot .bt_totals .tot,
.user-app-container-games .analysis_bot_tot .bt_totals .tot {
  width: 25%;
  float: left;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}
.account-container .bt_img.canvas_icon img,
.user-app-container .bt_img.canvas_icon img,
.user-app-container-alt .bt_img.canvas_icon img,
.user-app-container-result .bt_img.canvas_icon img,
.user-app-container-games .bt_img.canvas_icon img {
  width: 32px;
}
.account-container .filter_input_row .form-control,
.user-app-container .filter_input_row .form-control,
.user-app-container-alt .filter_input_row .form-control,
.user-app-container-result .filter_input_row .form-control,
.user-app-container-games .filter_input_row .form-control {
  font-size: 12px;
  padding: 6px 14px;
  width: 90px;
}
.account-container .ranking_filter .form-group,
.user-app-container .ranking_filter .form-group,
.user-app-container-alt .ranking_filter .form-group,
.user-app-container-result .ranking_filter .form-group,
.user-app-container-games .ranking_filter .form-group {
  margin: 5px 3px;
}
.account-container section.page-header .page-title.count.sm-icon .mat-icon,
.user-app-container section.page-header .page-title.count.sm-icon .mat-icon,
.user-app-container-alt section.page-header .page-title.count.sm-icon .mat-icon,
.user-app-container-result section.page-header .page-title.count.sm-icon .mat-icon,
.user-app-container-games section.page-header .page-title.count.sm-icon .mat-icon {
  width: 35px;
  height: 35px;
}
.account-container .activity_list,
.user-app-container .activity_list,
.user-app-container-alt .activity_list,
.user-app-container-result .activity_list,
.user-app-container-games .activity_list {
  display: block;
  width: 100%;
}
.account-container .activity_list p,
.user-app-container .activity_list p,
.user-app-container-alt .activity_list p,
.user-app-container-result .activity_list p,
.user-app-container-games .activity_list p {
  color: #6c431c;
  font-weight: 500;
  font-size: 15px;
}
.account-container .activity_list .big,
.user-app-container .activity_list .big,
.user-app-container-alt .activity_list .big,
.user-app-container-result .activity_list .big,
.user-app-container-games .activity_list .big {
  display: flow-root;
}
.account-container .activity_list .big span,
.user-app-container .activity_list .big span,
.user-app-container-alt .activity_list .big span,
.user-app-container-result .activity_list .big span,
.user-app-container-games .activity_list .big span {
  font-size: 26px;
}
.account-container .activity_list span,
.user-app-container .activity_list span,
.user-app-container-alt .activity_list span,
.user-app-container-result .activity_list span,
.user-app-container-games .activity_list span {
  float: right;
  color: #000;
}
.account-container .graph_title,
.user-app-container .graph_title,
.user-app-container-alt .graph_title,
.user-app-container-result .graph_title,
.user-app-container-games .graph_title {
  color: #6c431c;
  font-size: 18px;
  font-weight: 500;
}
.account-container .playing_icon,
.user-app-container .playing_icon,
.user-app-container-alt .playing_icon,
.user-app-container-result .playing_icon,
.user-app-container-games .playing_icon {
  float: right;
}
.account-container .timer-container,
.user-app-container .timer-container,
.user-app-container-alt .timer-container,
.user-app-container-result .timer-container,
.user-app-container-games .timer-container {
  position: relative;
}
.account-container .timer-container .mat-progress-spinner,
.user-app-container .timer-container .mat-progress-spinner,
.user-app-container-alt .timer-container .mat-progress-spinner,
.user-app-container-result .timer-container .mat-progress-spinner,
.user-app-container-games .timer-container .mat-progress-spinner {
  position: absolute;
  left: calc(50% - 30px);
  z-index: 1;
}
.account-container .timer-container .timer,
.user-app-container .timer-container .timer,
.user-app-container-alt .timer-container .timer,
.user-app-container-result .timer-container .timer,
.user-app-container-games .timer-container .timer {
  position: relative;
  z-index: 2;
}
.account-container .playing_icon,
.user-app-container .playing_icon,
.user-app-container-alt .playing_icon,
.user-app-container-result .playing_icon,
.user-app-container-games .playing_icon {
  float: right;
  cursor: pointer;
}
.account-container .playing_icon.mute,
.user-app-container .playing_icon.mute,
.user-app-container-alt .playing_icon.mute,
.user-app-container-result .playing_icon.mute,
.user-app-container-games .playing_icon.mute {
  position: relative;
}
.account-container .playing_icon.mute:after,
.user-app-container .playing_icon.mute:after,
.user-app-container-alt .playing_icon.mute:after,
.user-app-container-result .playing_icon.mute:after,
.user-app-container-games .playing_icon.mute:after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 120%;
  background-color: #1b3b73;
  border-radius: 2px;
  left: -2px;
  top: 12px;
  transform: rotate(135deg);
}
.account-container .rating-starts-container,
.user-app-container .rating-starts-container,
.user-app-container-alt .rating-starts-container,
.user-app-container-result .rating-starts-container,
.user-app-container-games .rating-starts-container {
  display: flex;
  align-items: baseline;
}
.account-container .rating-starts-container ul.rating-list:nth-child(2) mat-icon,
.user-app-container .rating-starts-container ul.rating-list:nth-child(2) mat-icon,
.user-app-container-alt .rating-starts-container ul.rating-list:nth-child(2) mat-icon,
.user-app-container-result .rating-starts-container ul.rating-list:nth-child(2) mat-icon,
.user-app-container-games .rating-starts-container ul.rating-list:nth-child(2) mat-icon {
  font-size: 25px;
  width: 28px;
  height: 40px;
}
.account-container .rating-starts-container ul.rating-list:nth-child(3) mat-icon,
.user-app-container .rating-starts-container ul.rating-list:nth-child(3) mat-icon,
.user-app-container-alt .rating-starts-container ul.rating-list:nth-child(3) mat-icon,
.user-app-container-result .rating-starts-container ul.rating-list:nth-child(3) mat-icon,
.user-app-container-games .rating-starts-container ul.rating-list:nth-child(3) mat-icon {
  font-size: 40px;
  width: 48px;
  height: 53px;
}
.account-container .rating-starts-container ul.rating-list:nth-child(4) mat-icon,
.user-app-container .rating-starts-container ul.rating-list:nth-child(4) mat-icon,
.user-app-container-alt .rating-starts-container ul.rating-list:nth-child(4) mat-icon,
.user-app-container-result .rating-starts-container ul.rating-list:nth-child(4) mat-icon,
.user-app-container-games .rating-starts-container ul.rating-list:nth-child(4) mat-icon {
  font-size: 55px;
  width: 53px;
  height: 65px;
}
.account-container .rating-starts-container ul.rating-list:nth-child(5) mat-icon,
.user-app-container .rating-starts-container ul.rating-list:nth-child(5) mat-icon,
.user-app-container-alt .rating-starts-container ul.rating-list:nth-child(5) mat-icon,
.user-app-container-result .rating-starts-container ul.rating-list:nth-child(5) mat-icon,
.user-app-container-games .rating-starts-container ul.rating-list:nth-child(5) mat-icon {
  font-size: 70px;
  width: 63px;
  height: 78px;
}
.account-container .rating-starts-container ul.rating-list:nth-child(6) mat-icon,
.user-app-container .rating-starts-container ul.rating-list:nth-child(6) mat-icon,
.user-app-container-alt .rating-starts-container ul.rating-list:nth-child(6) mat-icon,
.user-app-container-result .rating-starts-container ul.rating-list:nth-child(6) mat-icon,
.user-app-container-games .rating-starts-container ul.rating-list:nth-child(6) mat-icon {
  font-size: 85px;
  width: 80px;
  height: 90px;
}
.account-container #question-audio-player,
.user-app-container #question-audio-player,
.user-app-container-alt #question-audio-player,
.user-app-container-result #question-audio-player,
.user-app-container-games #question-audio-player {
  display: none !important;
}
.account-container #result-footer .dialog-link .text,
.user-app-container #result-footer .dialog-link .text,
.user-app-container-alt #result-footer .dialog-link .text,
.user-app-container-result #result-footer .dialog-link .text,
.user-app-container-games #result-footer .dialog-link .text {
  color: #6c431c;
  font-size: 12px;
  line-height: initial;
  font-weight: 600;
}
.account-container #result-footer .dialog-link,
.user-app-container #result-footer .dialog-link,
.user-app-container-alt #result-footer .dialog-link,
.user-app-container-result #result-footer .dialog-link,
.user-app-container-games #result-footer .dialog-link {
  text-align: center;
  cursor: pointer;
}
.account-container #har-footer .dialog-link,
.user-app-container #har-footer .dialog-link,
.user-app-container-alt #har-footer .dialog-link,
.user-app-container-result #har-footer .dialog-link,
.user-app-container-games #har-footer .dialog-link {
  text-align: center;
  cursor: pointer;
}
.account-container #har-footer .dialog-link .text,
.user-app-container #har-footer .dialog-link .text,
.user-app-container-alt #har-footer .dialog-link .text,
.user-app-container-result #har-footer .dialog-link .text,
.user-app-container-games #har-footer .dialog-link .text {
  color: #6c431c;
  font-size: 12px;
  line-height: initial;
  font-weight: 600;
}
.account-container #har-footer,
.user-app-container #har-footer,
.user-app-container-alt #har-footer,
.user-app-container-result #har-footer,
.user-app-container-games #har-footer {
  margin-top: 25px;
  margin-bottom: 15px;
}
.account-container .action,
.user-app-container .action,
.user-app-container-alt .action,
.user-app-container-result .action,
.user-app-container-games .action {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .account-container .tab_knowledge .mat-tab-label,
.user-app-container .tab_knowledge .mat-tab-label,
.user-app-container-alt .tab_knowledge .mat-tab-label,
.user-app-container-result .tab_knowledge .mat-tab-label,
.user-app-container-games .tab_knowledge .mat-tab-label {
    width: auto !important;
  }
}
@media (min-width: 550px) {
  .account-container .result-container #result-footer,
.user-app-container .result-container #result-footer,
.user-app-container-alt .result-container #result-footer,
.user-app-container-result .result-container #result-footer,
.user-app-container-games .result-container #result-footer {
    position: relative !important;
  }
}

.profile-menu {
  border-radius: 20px !important;
}
.profile-menu .menu-link {
  color: #959595;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
}
.profile-menu .menu-link .mat-icon {
  color: #959595;
}
.profile-menu .profile-link {
  height: fit-content;
}
.profile-menu .profile-link .link-container {
  display: flex;
  padding: 8px 0px;
}
.profile-menu .profile-link .link-container img {
  height: 50px;
  width: 50px;
  margin-right: 12px;
  border-radius: 50%;
}
.profile-menu .profile-link .link-container .text {
  height: fit-content;
  line-height: 21px;
}
.profile-menu .profile-link .link-container .text .name {
  height: fit-content;
  line-height: 19px;
  color: #a25833;
  font-size: 15px;
  font-weight: bold;
}
.profile-menu .profile-link .link-container .text .email {
  height: fit-content;
  line-height: 14px;
  color: #b9b9b9;
  font-size: 10px;
}
.profile-menu .profile-link .link-container .text .edit-link {
  height: fit-content;
  line-height: 14px;
  font-size: 10px;
}

.app-component-container {
  height: 100%;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

@media screen and (max-width: 376px) {
  .sieve-app .create-stepper-container {
    padding: 12px 0px !important;
  }
  .sieve-app .sieve-image-container {
    height: 300px;
    width: 280px;
  }
  .sieve-app .mat-horizontal-content-container {
    padding: 0 0px 24px 0px;
  }
  .sieve-app .play-container {
    margin-bottom: 10px !important;
    padding: 0 0px !important;
  }
  .sieve-app .play-area-container .players-list-container {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .sieve-app .play-area-container .players-list-container .player-conatiner img {
    max-width: 60px !important;
  }
  .sieve-app .play-area-container .players-list-container .player-conatiner .player-score {
    height: 28px !important;
    width: 28px !important;
    left: calc(50% - 14px) !important;
    font-size: 11px !important;
    line-height: 13px !important;
  }

  .sieve-matrix {
    height: calc(100vh - 232px) !important;
  }
}
@media screen and (min-width: 280px) {
  #myAnswer .bkpDiv {
    margin-left: -14px;
  }
  #myAnswer .mat-spinner-div {
    margin-left: -12px;
  }
  #myAnswer .submit-time {
    margin-left: -19px;
  }
  #myAnswer .correctness-container {
    margin-left: 9px;
  }
  #myAnswer .result-div {
    margin-left: -15px;
  }
  #myAnswer .bk-brain {
    width: 44px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    bottom: 7px;
    left: 6px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 7px;
    bottom: 41px;
    left: 7px;
    right: 7px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 7px;
  }
  #myAnswer .correctness-container img {
    width: 45px;
  }
  #myAnswer .result-div .result-p {
    text-align: center;
    margin-top: -26px;
    color: #1c3c73;
    font-size: 14px;
    font-weight: bold;
  }
  #myAnswer .result-correctness {
    text-align: center;
    margin-top: -31px;
    position: relative;
  }
  #myAnswer .submit-time {
    font-size: 11px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 16px;
    color: #6de354;
    width: 79px;
    margin-left: -19px;
  }
  #myAnswer .mat-spinner-div .time-taken {
    text-align: center;
    margin-top: -28px;
    position: relative;
    font-size: 15px;
  }
  #myAnswer .mat-progress-spinner {
    width: 35px;
    height: 35px;
  }

  #resultPage .bk-container .bk-brain {
    width: 150px;
  }
  #resultPage .bk-container .bk-value {
    position: absolute;
    top: 19px;
    left: 27px;
    font-size: 32px;
    line-height: 59px;
    font-weight: bold;
    text-align: center;
    color: #1c3c73;
  }
  #resultPage .mat-icon.help-icon {
    height: 18px;
    width: 18px;
    line-height: 20px;
    font-size: 20px;
  }
  #resultPage .bk-container .bk-details .result-icon img {
    width: 80%;
  }
  #resultPage .bk-container .bk-details .bible-icon {
    margin-bottom: 15px;
  }
  #resultPage .bk-container .bk-details .score {
    color: #0c3c77;
    font-size: 25px;
    line-height: 38px;
    font-weight: bold;
  }
  #resultPage .bk-container .bk-details {
    position: absolute;
    background: white;
    top: 103px;
    left: 29px;
    padding: 8px;
    border-radius: 50px;
    min-height: 120px;
    min-width: 67px;
    text-align: center;
    cursor: pointer;
  }
  #resultPage .result {
    font-size: 33px;
    line-height: 47px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-top: 4px;
    margin-left: -10px;
  }
  #resultPage #result-footer div button {
    width: 100%;
  }
  #resultPage #result-footer div button span img {
    margin-left: -15px;
  }
  #resultPage #result-footer div button span span {
    margin-left: -5px;
    font-size: 11px;
  }

  #possibleResult .possible-results {
    padding: 15px;
    border-radius: 30px;
    background-color: white;
    height: 100%;
    width: -webkit-max-content;
    width: auto;
  }
}
@media screen and (min-width: 320px) {
  #myAnswer .bkpDiv {
    margin-left: -14px;
  }
  #myAnswer .mat-spinner-div {
    margin-left: -2px;
  }
  #myAnswer .result-div {
    margin-left: -6px;
  }
  #myAnswer .bk-brain {
    width: 44px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    bottom: 7px;
    left: 6px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 7px;
    bottom: 41px;
    left: 7px;
    right: 7px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 14px;
  }
  #myAnswer .correctness-container img {
    width: 45px;
  }
  #myAnswer .result-div .result-p {
    text-align: center;
    margin-top: -26px;
    color: #1c3c73;
    font-size: 14px;
    font-weight: bold;
  }
  #myAnswer .result-correctness {
    text-align: center;
    margin-top: -31px;
    position: relative;
  }
  #myAnswer .submit-time {
    font-size: 11px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 16px;
    color: #6de354;
    width: 79px;
    margin-left: -8px;
  }
  #myAnswer .mat-spinner-div .time-taken {
    text-align: center;
    margin-top: -28px;
    position: relative;
    font-size: 15px;
  }
  #myAnswer .mat-progress-spinner {
    width: 35px;
    height: 35px;
  }

  #resultPage .bk-container .bk-brain {
    width: 150px;
  }
  #resultPage .bk-container .bk-value {
    position: absolute;
    top: 19px;
    left: 27px;
    font-size: 32px;
    line-height: 59px;
    font-weight: bold;
    text-align: center;
    color: #1c3c73;
  }
  #resultPage .mat-icon.help-icon {
    height: 18px;
    width: 18px;
    line-height: 20px;
    font-size: 20px;
  }
  #resultPage .bk-container .bk-details .result-icon img {
    width: 80%;
  }
  #resultPage .bk-container .bk-details .bible-icon {
    margin-bottom: 15px;
  }
  #resultPage .bk-container .bk-details .score {
    color: #0c3c77;
    font-size: 25px;
    line-height: 38px;
    font-weight: bold;
  }
  #resultPage .bk-container .bk-details {
    position: absolute;
    background: white;
    top: 103px;
    left: 29px;
    padding: 8px;
    border-radius: 50px;
    min-height: 120px;
    min-width: 67px;
    text-align: center;
    cursor: pointer;
  }
  #resultPage .result {
    font-size: 33px;
    line-height: 47px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-top: 4px;
    margin-left: -10px;
  }
  #resultPage #result-footer div button {
    width: 100%;
  }
  #resultPage #result-footer div button span img {
    margin-left: -15px;
  }
  #resultPage #result-footer div button span span {
    margin-left: -5px;
    font-size: 11px;
  }

  #possibleResult .possible-results {
    padding: 15px;
    border-radius: 30px;
    background-color: white;
    height: 100%;
    width: -webkit-max-content;
    width: auto;
  }
}
@media screen and (min-width: 360px) {
  #myAnswer .bkpDiv {
    margin-left: 0px;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 16px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 18px;
    line-height: 16px;
    font-weight: bold;
    bottom: 10px;
    left: 8px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 8px;
    bottom: 28px;
    left: 6px;
    right: 8px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .bk-brain {
    width: 46px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    bottom: 7px;
    left: 6px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 7px;
    bottom: 41px;
    left: 7px;
    right: 7px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 14px;
  }
  #myAnswer .correctness-container img {
    width: 45px;
  }
  #myAnswer .result-div {
    margin-left: -4px;
  }
  #myAnswer .result-div .result-p {
    text-align: center;
    margin-top: -26px;
    color: #1c3c73;
    font-size: 14px;
    font-weight: bold;
  }
  #myAnswer .result-correctness {
    text-align: center;
    margin-top: -31px;
    position: relative;
  }
  #myAnswer .submit-time {
    font-size: 11px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 16px;
    color: #6de354;
    width: 79px;
    margin-left: -3px;
  }
  #myAnswer .mat-spinner-div {
    margin-left: -6px;
  }
  #myAnswer .mat-spinner-div .time-taken {
    text-align: center;
    margin-top: -28px;
    position: relative;
    font-size: 15px;
  }
  #myAnswer .mat-progress-spinner {
    width: 35px;
    height: 35px;
  }
}
@media screen and (min-width: 375px) {
  #myAnswer .bk-value {
    position: absolute;
    font-size: 18px;
    line-height: 16px;
    font-weight: bold;
    bottom: 10px;
    left: 8px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 8px;
    bottom: 28px;
    left: 6px;
    right: 8px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .submit-time {
    margin-left: -18px;
  }
  #myAnswer .bk-brain {
    width: 54px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    bottom: 8px;
    left: 8px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 7px;
    bottom: 41px;
    left: 7px;
    right: 7px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 20px;
  }
  #myAnswer .correctness-container img {
    width: 50px;
  }
  #myAnswer .result-div .result-p {
    text-align: center;
    margin-top: -26px;
    color: #1c3c73;
    font-size: 14px;
    font-weight: bold;
  }
  #myAnswer .result-correctness {
    text-align: center;
    margin-top: -31px;
    position: relative;
  }
  #myAnswer .submit-time {
    font-size: 11px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 16px;
    color: #6de354;
    width: 79px;
    margin-left: -8px;
  }
  #myAnswer .mat-spinner-div .time-taken {
    text-align: center;
    margin-top: -28px;
    position: relative;
    font-size: 15px;
  }
  #myAnswer .mat-progress-spinner {
    width: 35px;
    height: 35px;
  }
}
@media screen and (min-width: 411px) {
  #myAnswer .bk-value {
    position: absolute;
    font-size: 18px;
    line-height: 16px;
    font-weight: bold;
    bottom: 10px;
    left: 8px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 8px;
    bottom: 28px;
    left: 6px;
    right: 8px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    margin-left: 20px;
  }
  #myAnswer .result-div {
    margin-left: 0px;
  }
  #myAnswer .submit-time {
    margin-left: 0px;
  }
  #myAnswer .bk-brain {
    width: 60px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    bottom: 11px;
    left: 11px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 7px;
    bottom: 41px;
    left: 7px;
    right: 7px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 6px;
  }
  #myAnswer .correctness-container img {
    width: 60px;
  }
  #myAnswer .result-div .result-p {
    text-align: center;
    margin-top: -30px;
    color: #1c3c73;
    font-size: 16px;
    font-weight: bold;
  }
  #myAnswer .result-correctness {
    text-align: center;
    margin-top: -40px;
    position: relative;
  }
  #myAnswer .submit-time {
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 16px;
    color: #6de354;
    width: 79px;
    margin-left: 0px;
  }
  #myAnswer .mat-spinner-div {
    margin-left: 0px;
  }
  #myAnswer .mat-spinner-div .time-taken {
    text-align: center;
    margin-top: -30px;
    position: relative;
    font-size: 16px;
  }
  #myAnswer .mat-progress-spinner {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 414px) {
  #myAnswer .overlay {
    position: absolute;
    top: 8px;
    bottom: 28px;
    left: 6px;
    right: 8px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .submit-time {
    margin-left: 0px;
  }
  #myAnswer .bk-brain {
    width: 65px;
  }
  #myAnswer .bk-value {
    position: absolute;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    bottom: 12px;
    left: 13px;
    color: #1c3c73;
  }
  #myAnswer .overlay {
    position: absolute;
    top: 8px;
    bottom: 38px;
    left: 7px;
    right: 7px;
    background: #00000038;
    border-radius: 50%;
  }
  #myAnswer .correctness-container {
    position: relative;
    margin-right: 18px;
    color: white;
    font-size: 16px;
    margin-left: 6px;
  }
  #myAnswer .correctness-container img {
    width: 60px;
  }
  #myAnswer .result-div .result-p {
    text-align: center;
    margin-top: -31px;
    color: #1c3c73;
    font-size: 16px;
    font-weight: bold;
  }
  #myAnswer .result-correctness {
    text-align: center;
    margin-top: -40px;
    position: relative;
  }
  #myAnswer .submit-time {
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 16px;
    color: #6de354;
    width: 12vw;
    margin-left: -5px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  #myAnswer .mat-spinner-div .time-taken {
    text-align: center;
    margin-top: -28px;
    position: relative;
    font-size: 18px;
  }
  #myAnswer .mat-progress-spinner {
    width: 40px;
    height: 40px;
  }
}
.app-dialog.cdk-overlay-pane {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.app-dialog.cdk-overlay-pane .mat-dialog-container {
  border-radius: unset !important;
  background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
  position: relative;
}
.app-dialog.cdk-overlay-pane .mat-dialog-container button.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: white;
}
.app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
  margin-left: 12px;
  border-bottom: 1px solid #1b3b73;
}
.app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 38px;
}
.app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  overflow: auto;
}

.condition-dialog.cdk-overlay-pane {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.condition-dialog.cdk-overlay-pane .mat-dialog-container {
  border-radius: unset !important;
  background: linear-gradient(180deg, #f3f3f3 0%, #fafafa 100%);
  position: relative;
}
.condition-dialog.cdk-overlay-pane .mat-dialog-container button.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: #969696;
}
.condition-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
  text-align: center;
  color: #050505;
  display: flex;
  align-items: center;
  justify-content: center;
}
.condition-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
  margin-left: 12px;
  border-bottom: 1px solid #f9f9fa;
}
.condition-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 38px;
}
.condition-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  overflow: auto;
}

.sieve-user-dialog.cdk-overlay-pane {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.sieve-user-dialog.cdk-overlay-pane button.rounded {
  border-radius: 23px !important;
  padding: 0 24px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group {
  margin-bottom: 1rem;
}
.sieve-user-dialog.cdk-overlay-pane .form-group label {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: white;
  padding-left: 12px;
  margin-bottom: 0.1rem;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .form-control {
  border-radius: 40px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .form-control:disabled,
.sieve-user-dialog.cdk-overlay-pane .form-group .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .with-suffix.toggle .suffix {
  color: white;
  background-color: #484848;
  border-radius: 50%;
  padding: 3px;
  font-size: 18px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .with-suffix .suffix {
  color: #f2b40b;
  position: absolute;
  right: 8px;
  bottom: 7px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group.with-suffix .suffix {
  color: #f2b40b;
  position: absolute;
  right: 8px;
  bottom: 7px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group.with-suffix.toggle .suffix {
  color: white;
  background-color: #484848;
  border-radius: 50%;
  padding: 3px;
  font-size: 18px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group.with-suffix.toggle.active .suffix {
  background-color: #6de354;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .form-control-group.contact-number {
  display: flex;
  gap: 16px;
}
.sieve-user-dialog.cdk-overlay-pane .form-group .form-control-group.contact-number .country-code {
  width: 120px;
  left: 0;
  top: 0;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container {
  border-radius: unset !important;
  background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
  position: relative;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container button.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: white;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
  margin-left: 12px;
  border-bottom: 1px solid #1b3b73;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 38px;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  overflow: auto;
}
.sieve-user-dialog.cdk-overlay-pane .mat-dialog-container .button-loader {
  position: absolute !important;
  right: 0px;
  top: 7px;
}

.arrow-icon {
  transform: translateY(7px);
}

@media screen and (min-width: 280px) {
  #nChartResponsive .first-help-icon {
    top: -3px;
    left: -27px;
  }
  #nChartResponsive .second-help-icon {
    bottom: 29px;
    left: -25px;
  }
}
@media screen and (min-width: 320px) {
  #nChartResponsive .first-help-icon {
    top: -3px;
    left: -31px;
  }
  #nChartResponsive .second-help-icon {
    bottom: 29px;
    left: -30px;
  }
}
@media screen and (min-width: 360px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -33px;
    top: -4px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -33px;
    bottom: 30px;
  }
}
@media screen and (min-width: 411px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -41px;
    top: -6px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -40px;
    bottom: 32px;
  }
}
@media screen and (min-width: 502px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -41px;
    top: -6px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -40px;
    bottom: 32px;
  }
}
@media screen and (min-width: 540px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -58px;
    top: -11px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -56px;
    bottom: 41px;
  }
}
@media screen and (min-width: 768px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -77px;
    top: -17px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -78px;
    bottom: 46px;
  }
}
@media screen and (min-width: 974px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -107px;
    top: -25px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -107px;
    bottom: 54px;
  }
}
@media screen and (min-width: 1203px) {
  #nChartResponsive .first-help-icon {
    position: absolute;
    left: -132px;
    top: -29px;
  }
  #nChartResponsive .second-help-icon {
    position: absolute;
    left: -130px;
    bottom: 60px;
  }
}
@media screen and (max-width: 320px) {
  #select-avatar .add-img {
    height: 70.6px;
    width: 70.6px;
    margin-top: 10px;
    background-color: white;
    border-radius: 50%;
  }
  #select-avatar .add-img-div {
    margin-left: -10px;
  }
}
@media screen and (min-width: 360px) {
  #select-avatar .add-img {
    height: 84px;
    width: 84px;
    margin-top: 10px;
    background-color: white;
    border-radius: 50%;
  }
  #select-avatar .add-img-div {
    margin-left: -10px;
  }
}
@media screen and (min-width: 375px) {
  #select-avatar .add-img {
    height: 89px;
    width: 89px;
    margin-top: 10px;
    background-color: white;
    border-radius: 50%;
  }
  #select-avatar .add-img-div {
    margin-left: -10px;
  }
}
@media screen and (min-width: 411px) {
  #select-avatar .add-img {
    height: 100.98px;
    width: 100.98px;
    background-color: white;
    border-radius: 50%;
  }
  #select-avatar .add-img-div {
    margin-left: 10px;
  }
}
.virus-free-app-container {
  /* Add application styles & imports to this file! */
  @import url("https://fonts.googleapis.com/css?family=Spectral+SC:700");
  /** Colors variables */
  /** share button variables */
  /** share buttons colors */
  /** Colors variables */
  /** share button variables */
  /** share buttons colors */
  /** Default style */
  /** Colors variables */
  /** share button variables */
  /** share buttons colors */
  /** Colors variables */
  /** share button variables */
  /** share buttons colors */
  /** Default style */
  height: 100% !important;
}
.virus-free-app-container :host::ng-deep.hexinner {
  background-color: #fff;
  margin: 2px solid black;
}
.virus-free-app-container :host::ng-deep.ex5 .hex.optionHex:hover .hexinner {
  /* We have created the hexagon via clip-path so we have to simulate
  a border using two nested elements. We can set the border colour
  using "background" and the border width using "margin" */
  color: black;
  margin: 5dpx;
  cursor: pointer;
  box-shadow: inset 0px 2px 28px #00000073;
}
.virus-free-app-container :host::ng-deep.optionHex.selectedHex .hexinner {
  background-color: #fff;
  box-shadow: inset 0px 2px 28px #00000073;
}
.virus-free-app-container .bgcolor {
  padding-top: 12px;
  background-color: #6066d0;
}
.virus-free-app-container .body-container {
  width: 400px;
  position: relative;
}
.virus-free-app-container .question {
  padding: 12px;
  background-color: white;
  color: #003c7b;
  border-radius: 10px;
  position: relative;
}
.virus-free-app-container .option {
  text-align: center;
  padding: 10px;
  background-color: #fca82e;
  color: white;
  border-radius: 10px;
  z-index: 1;
}
.virus-free-app-container .optionRow {
  color: white;
}
.virus-free-app-container .prevButton {
  z-index: 5;
}
.virus-free-app-container .nextButton {
  z-index: 5;
}
.virus-free-app-container h3.app_title {
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-family: MTF Chubb, Fallback, sans-serif;
}
.virus-free-app-container .question_title {
  position: absolute;
  top: -19px;
  left: 20px;
  background: #fca82e;
  color: #fff;
  padding: 4px 13px;
  border-radius: 10px;
  font-size: 12px;
}
.virus-free-app-container .question {
  border: 5px solid #ffffff05;
  transition: border 0.225s;
  margin-top: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 36px !important;
}
.virus-free-app-container .question .question-text {
  height: 88px;
  overflow: auto;
  font-size: 14px;
  line-height: 17px;
}
.virus-free-app-container .question .pre-text {
  height: 35px;
  overflow: auto;
  font-size: 14px;
  line-height: 17px;
}
.virus-free-app-container .start_hexa {
  width: 50px;
  height: 46px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  position: absolute;
  top: -27px;
  right: 19px;
}
.virus-free-app-container .start_hexa:after {
  width: 43px;
  height: 41px;
  background: #6066d0;
  content: "";
  position: absolute;
  z-index: -5;
  clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
}
.virus-free-app-container .option_block {
  background: #fca82e;
  background-image: url(/assets/virus-free/ans_bg.png);
  width: 85%;
  margin: 0 auto;
  height: 90px;
  border-radius: 15px;
  background-size: 100% 100%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 15px 15px 20px 15px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
}
.virus-free-app-container .option_block.quarantined {
  background-color: red !important;
  background-image: url(/assets/virus-free/ans_error_bg.png) !important;
}
.virus-free-app-container .bottom_options {
  position: absolute;
  background: #fca82e;
  border-radius: 15px;
  background-image: url(/assets/virus-free/options_bg.png);
  background-size: cover;
  padding: 12px 0;
  width: calc(100% - 16px);
  bottom: 8px;
  min-height: 85px;
}
.virus-free-app-container .bottom_options .options {
  width: 25%;
  display: inline-block;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.virus-free-app-container .bottom_options .options img {
  display: block;
  margin: 0 auto;
}
.virus-free-app-container .bottom_options .options span {
  font-size: 15px;
  margin-top: 5px;
  display: block;
}
.virus-free-app-container .body_bg {
  transition: border 0.225s;
  background: #fff;
  border-radius: 15px;
  padding: 7px 7px;
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 84px;
  min-height: 400px;
  max-height: 80vh;
  background-repeat: no-repeat;
  background-origin: content-box;
}
.virus-free-app-container .body_bg img {
  height: 100%;
}
.virus-free-app-container .options_hexa {
  font-weight: 600;
  width: 60px;
  font-size: 12px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ef9f2c;
  position: relative;
  right: 15px;
}
.virus-free-app-container .arrow_hexa_option {
  filter: drop-shadow(0px 0px 4px #00000014);
}
.virus-free-app-container .hexa_arrow {
  position: absolute;
  right: 3%;
  bottom: 118px;
  width: 55px;
}
.virus-free-app-container .opt_arrow {
  position: absolute;
}
.virus-free-app-container .opt_arrow.opt1 {
  transform: rotate(-60deg);
  top: -5px;
  left: -26px;
}
.virus-free-app-container .opt_arrow.opt2 {
  transform: rotate(0deg);
  top: -23px;
  left: 7px;
}
.virus-free-app-container .opt_arrow.opt3 {
  transform: rotate(60deg);
  top: -4px;
  right: -2px;
}
.virus-free-app-container .opt_arrow.opt4 {
  transform: rotate(-120deg);
  bottom: 0px;
  left: -26px;
}
.virus-free-app-container .opt_arrow.opt5 {
  transform: rotate(120deg);
  bottom: 1px;
  right: -2px;
}
.virus-free-app-container .opt_arrow.opt6 {
  transform: rotate(180deg);
  bottom: -18px;
  left: 7px;
}
.virus-free-app-container .arrow_img {
  position: absolute;
  clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  width: 50px;
  height: 46px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.virus-free-app-container .direction_arrow {
  filter: drop-shadow(0px 3px 4px #0000004d);
  position: absolute;
  bottom: 24px;
  left: 20px;
}
.virus-free-app-container .direction_arrow img {
  transition: 0.225s;
}
.virus-free-app-container .direction_arrow .direction1 img {
  transform: rotate(0deg);
}
.virus-free-app-container .direction_arrow .direction2 img {
  transform: rotate(60deg);
}
.virus-free-app-container .direction_arrow .direction3 img {
  transform: rotate(120deg);
}
.virus-free-app-container .direction_arrow .direction4 img {
  transform: rotate(180deg);
}
.virus-free-app-container .direction_arrow .direction5 img {
  transform: rotate(240deg);
}
.virus-free-app-container .direction_arrow .direction6 img {
  transform: rotate(300deg);
}
.virus-free-app-container .hexa_map {
  margin: 0 auto;
  padding-bottom: 22px;
}
.virus-free-app-container .currentHex .hexinner::before,
.virus-free-app-container .path .hexinner::before {
  content: "";
  background: url("/assets/virus-free/path_arrow.png");
  width: 12px;
  height: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -4px;
  z-index: -1;
  visibility: hidden;
}
.virus-free-app-container .currentHex.option1 .hexinner::before,
.virus-free-app-container .path.option1 .hexinner::before {
  top: -2px;
  left: 18px;
  transform: rotate(0deg);
  visibility: visible;
}
.virus-free-app-container .currentHex.option2 .hexinner::before,
.virus-free-app-container .path.option2 .hexinner::before {
  top: 8px;
  right: 1px;
  transform: rotate(64deg);
  visibility: visible;
}
.virus-free-app-container .currentHex.option3 .hexinner::before,
.virus-free-app-container .path.option3 .hexinner::before {
  bottom: 6px;
  right: 1px;
  transform: rotate(121deg);
  visibility: visible;
}
.virus-free-app-container .currentHex.option4 .hexinner::before,
.virus-free-app-container .path.option4 .hexinner::before {
  bottom: -3px;
  left: 18px;
  transform: rotate(180deg);
  visibility: visible;
}
.virus-free-app-container .currentHex.option5 .hexinner::before,
.virus-free-app-container .path.option5 .hexinner::before {
  bottom: 6px;
  left: 2px;
  transform: rotate(240deg);
  visibility: visible;
}
.virus-free-app-container .currentHex.option6 .hexinner::before,
.virus-free-app-container .path.option6 .hexinner::before {
  bottom: 28px;
  left: 2px;
  transform: rotate(300deg);
  visibility: visible;
}
.virus-free-app-container .pop_heading {
  color: #fca82f;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}
.virus-free-app-container .pop_btext_sm {
  color: #003c7b;
  font-size: 13px;
  text-align: center;
}
.virus-free-app-container .form_set label {
  display: block;
  line-height: initial;
  color: #003c7b;
  font-weight: 400;
  font-size: 15px;
}
.virus-free-app-container .form_set input {
  width: 100%;
  border: 1px solid #ececec;
  padding: 6px 12px;
  border-radius: 35px;
  color: #003c7b;
}
.virus-free-app-container .form_set {
  margin-bottom: 15px;
}
.virus-free-app-container .or_divide {
  text-align: center;
  font-size: 23px;
  color: #fca82f;
  margin: 17px 0;
  position: relative;
}
.virus-free-app-container .or_divide:after {
  content: "";
  width: 42%;
  background: #ddd;
  height: 2px;
  position: absolute;
  right: 0;
  top: 11px;
}
.virus-free-app-container .or_divide:before {
  content: "";
  width: 42%;
  background: #ddd;
  height: 2px;
  position: absolute;
  left: 0;
  top: 11px;
}
.virus-free-app-container .social_login ul {
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.virus-free-app-container .social_login ul li img {
  width: 70px;
}
.virus-free-app-container .model_head {
  text-align: center;
  margin-bottom: 25px;
}
.virus-free-app-container .model_button {
  text-align: center;
  margin-top: 30px;
}
.virus-free-app-container button.pop_btn {
  background-color: #fca82f;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 8px 15px;
  min-width: 120px;
  background-image: url(/assets/virus-free/pop_btn_bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.virus-free-app-container .popup_dialog mat-dialog-container {
  background-image: url(/assets/virus-free/model_man.png), url(/assets/virus-free/model_botimg.png);
  background-repeat: no-repeat;
  background-position: 8% bottom, right bottom;
  border-radius: 15px !important;
  position: relative;
  overflow: inherit;
}
.virus-free-app-container .model_close {
  position: absolute;
  top: -25px;
  z-index: 9999;
  right: 8px;
}
.virus-free-app-container .share_fb {
  position: absolute;
  bottom: 9px;
  right: 0;
}
.virus-free-app-container .hr {
  width: 100%;
  height: 2px;
  background: #ddd;
  margin-bottom: 20px;
}
.virus-free-app-container .nocovid {
  position: absolute;
  top: -33px;
}
.virus-free-app-container .popup_dialog .mat-dialog-container {
  padding-top: 35px;
}
.virus-free-app-container .bible_vers_list ul {
  list-style: none;
  padding: 0;
}
.virus-free-app-container .bible_vers_list ul li {
  text-align: center;
  color: #003c7b;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}
.virus-free-app-container .bible_vers_list ul li:last-child {
  border-bottom: none;
}
.virus-free-app-container .danger_block .start_hexa::after {
  background: #ff0000;
}
.virus-free-app-container .danger_block .question {
  border: 5px solid red;
}
.virus-free-app-container .danger_block .body_bg {
  border: 5px solid red;
}
.virus-free-app-container .danger_icon {
  position: absolute;
}
.virus-free-app-container .danger_icon img,
.virus-free-app-container .success_icon img {
  width: 65px;
}
.virus-free-app-container .danger_icon,
.virus-free-app-container .success_icon {
  position: absolute;
  left: 18px;
  top: 60px;
}
.virus-free-app-container .rem_days {
  position: absolute;
  left: 0;
  bottom: 0;
}
.virus-free-app-container .rem_days img {
  width: 65px;
}
.virus-free-app-container .maze {
  overflow-y: auto;
  height: 100%;
  padding-top: 24px;
  background: #ffffffbf;
  border-radius: 8px;
}
.virus-free-app-container .maze.level-1 {
  padding-bottom: 18px !important;
}
.virus-free-app-container .maze.level-2 {
  padding-top: 52px !important;
  padding-bottom: 24px !important;
}
.virus-free-app-container .maze.level-3 {
  padding-top: 50px !important;
  padding-bottom: 90px !important;
}
.virus-free-app-container .maze.level-4,
.virus-free-app-container .maze.level-5,
.virus-free-app-container .maze.level-6 {
  padding-top: 34px !important;
}
.virus-free-app-container .rem_days .title {
  color: #2b5589;
  text-align: center;
  font-size: 11px;
  line-height: initial;
  font-weight: 600;
}
.virus-free-app-container .rem_days span {
  position: absolute;
  top: 37%;
  left: 28%;
  min-width: 28px;
  font-size: 23px !important;
  margin-top: unset !important;
  font-weight: 600;
  color: #335c8d;
}
.virus-free-app-container .rem_days {
  position: absolute;
  left: calc(50% - 32px);
  bottom: 66px;
}
.virus-free-app-container .danger_block .hex.currentHex {
  background: red !important;
}
.virus-free-app-container .profile_block {
  background: #fff;
  border-radius: 15px;
  position: relative;
  margin-top: 12px;
  box-shadow: 3px 3px 3px 3px #0000002b;
}
.virus-free-app-container .prof_section .welcome {
  width: 60%;
  float: left;
}
.virus-free-app-container .prof_section .avatar {
  width: 40%;
  float: left;
}
.virus-free-app-container .prof_section {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  min-height: 120px;
  padding-top: 10px;
}
.virus-free-app-container .prof_section .welcome {
  width: 50%;
  float: left;
  color: #003c7b;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  text-shadow: 0px 0px 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 0%;
  margin-bottom: 25px;
}
.virus-free-app-container .prof_buttons .orange_btn {
  background: #fca82f;
  color: #fff;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  padding: 7px 14px;
  line-height: 21px;
  cursor: pointer;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.24), 0 12px 35px 0 rgba(0, 0, 0, 0.19);
}
.virus-free-app-container .prof_buttons {
  position: absolute;
  bottom: -19px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
}
.virus-free-app-container .prof_section .avatar img {
  width: 80px;
  position: relative;
  top: -6px;
  height: 80px;
  margin-left: 18px;
}
.virus-free-app-container .page_title {
  text-align: center;
  color: #fff;
  font-size: 25px;
  text-shadow: 0px 0px 3px;
}
.virus-free-app-container .leader_table th,
.virus-free-app-container .leader_table td {
  text-align: center;
  padding: 4px 0;
}
.virus-free-app-container .leader_table th {
  color: #003c7b;
}
.virus-free-app-container .body_content {
  background: #fff;
  border-radius: 15px;
  padding: 30px 12px 12px;
  position: relative;
  margin-top: 35px;
}
.virus-free-app-container .leader_table tr {
  border-bottom: 1px solid #d5d3d3;
}
.virus-free-app-container .leader_table tr:first-child {
  border-bottom: 0px;
}
.virus-free-app-container .ranking {
  position: relative;
  display: inline-block;
}
.virus-free-app-container .ranking span {
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
  font-weight: 600;
  color: #003c7b;
}
.virus-free-app-container .reg_save {
  text-align: center;
}
.virus-free-app-container .leader_table tr.select td {
  color: #003c7b;
  font-weight: 600;
}
.virus-free-app-container .drop_orange .mat-form-field-flex {
  background: #fca82f;
  padding: 7px 8px;
  border-radius: 40px;
}
.virus-free-app-container .drop_orange .mat-form-field-infix {
  border-top: 0px;
  width: 100px;
  padding: 4px 0px 4px 9px;
}
.virus-free-app-container .mat-select-arrow {
  color: white;
}
.virus-free-app-container .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.virus-free-app-container .mat-select-value {
  color: #fff;
}
.virus-free-app-container .level_drop {
  position: absolute;
  top: -20px;
  right: 15px;
}
.virus-free-app-container .profile_drop {
  position: absolute;
  top: 8px;
  right: -40px;
}
.virus-free-app-container .ls_title .level_title {
  display: table;
  float: left;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}
.virus-free-app-container .ls_title .score_title {
  display: table;
  float: right;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 125px;
  text-align: center;
}
.virus-free-app-container .ls_title {
  display: table;
  width: 100%;
}
.virus-free-app-container .level_box {
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 55px;
  margin-bottom: 8px;
  gap: 2px;
  padding-right: 0px;
  cursor: pointer;
}
.virus-free-app-container .score_box,
.virus-free-app-container .score_section .raf_text {
  display: flex;
  align-items: center;
  color: #fff;
}
.virus-free-app-container .score_box {
  overflow: hidden;
  background-color: #fca82f;
  background-image: url(/assets/virus-free/Intersection19.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 30%;
  margin-top: -10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 22px;
  text-align: center;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 0px;
}
.virus-free-app-container .score_section .raf_text {
  color: white;
  background: #003c7b;
  position: absolute;
  right: 110px;
  width: 110px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 17px;
  border-radius: 15px 0 0 15px;
  font-size: 16px;
  font-weight: 500;
}
.virus-free-app-container button.level_btn {
  border: none;
  padding: 7px 14px;
  color: #fff;
  border-radius: 30px;
  /* position: sticky; */
  bottom: -26px;
  left: 42px;
  /* min-width: 100px; */
  font-size: 14px;
  box-shadow: 0px 3px 6px #0000001f;
  margin-left: 5px;
}
.virus-free-app-container button.level_btn.locked {
  background: #d1d1d1;
}
.virus-free-app-container button.level_btn.active {
  background: #24e157;
}
.virus-free-app-container button.level_btn.completed {
  background: #fca82f;
}
.virus-free-app-container .level_box .level {
  position: relative;
  font-size: 14px;
  color: #003c7b;
  font-weight: 600;
  padding-left: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.virus-free-app-container .avatars.popup_dialog mat-dialog-container {
  background-image: none;
}
.virus-free-app-container .pop_avatar_select .options h2 {
  color: #082252;
  font-size: 18px;
}
.virus-free-app-container .pop_avatar_select .options .avatar {
  text-align: center;
  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000036;
}
.virus-free-app-container .options.selected .avatar {
  border: 3px solid #082252;
  position: relative;
}
.virus-free-app-container .options.selected .avatar:after {
  content: "";
  background: url(/assets/virus-free/select.png);
  width: 25px;
  height: 25px;
  position: absolute;
  background-size: 100%;
  top: 13px;
  right: 3px;
}
.virus-free-app-container .popup_dialog.plain_dialog .mat-dialog-container {
  background-image: none;
}
.virus-free-app-container .score h5 {
  line-height: initial;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 3px;
}
.virus-free-app-container .smily {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.virus-free-app-container .avatar_change {
  text-align: center;
  display: flex;
  width: 125px;
  height: 125px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000036;
  position: absolute;
  top: -64px;
  left: 0;
  right: 0;
  background: #fca82f;
}
.virus-free-app-container .edit_img {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50px;
  right: 5px;
  bottom: 17px;
  box-shadow: 0px 3px 6px #00000030;
}
.virus-free-app-container .popup_dialog.profile_pop .mat-dialog-container {
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/assets/virus-free/model_botimg.png);
  background-position: right bottom;
}
.virus-free-app-container .model_button.float_btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -17px;
}
.virus-free-app-container .rules_block .rules {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 15px;
  position: relative;
  display: flex;
  min-height: 170px;
}
.virus-free-app-container .rules_content {
  width: 60%;
  float: left;
  padding: 15px;
  color: #003c7b;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.virus-free-app-container .rules_img {
  background: #fca82f;
  width: 40%;
  float: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.virus-free-app-container .rules_btn button {
  border: none;
  min-width: 120px;
  font-size: 15px;
  color: #fff;
  padding: 7px 10px;
  border-radius: 50px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.virus-free-app-container .rules_btn {
  display: flex;
  justify-content: space-between;
}
.virus-free-app-container .rules_btn .blue_btn {
  background: #003c7b;
  background-image: url(/assets/virus-free/btn_bg_blue.png) !important;
}
.virus-free-app-container .rules_btn .orange_btn {
  background-image: url(/assets/virus-free/btn_bg_orange.png) !important;
  background: #fca82f;
}
@media (min-width: 425px) {
  .virus-free-app-container .popup_dialog {
    min-width: 360px;
    width: 360px;
  }
}
.virus-free-app-container input:focus,
.virus-free-app-container button:focus,
.virus-free-app-container a:focus {
  outline: none !important;
}
.virus-free-app-container .loader-conatiner .mat-spinner {
  margin: 0 auto;
}
.virus-free-app-container .hexinner {
  color: black !important;
}
.virus-free-app-container .hex.selectedHex {
  background-color: #fca82f !important;
  z-index: 2 !important;
}
.virus-free-app-container .hex.selectedHex .hexinner {
  margin: 3px;
  cursor: pointer;
}
.virus-free-app-container .bottom_options .options.disabled {
  color: #ffffff7a;
}
.virus-free-app-container .bottom_options .options.disabled img {
  filter: opacity(50%);
}
.virus-free-app-container .bottom_options .options.quarantined {
  color: #ffffff7a;
  width: 50% !important;
}
.virus-free-app-container .primary-text {
  color: #003c7b;
}
.virus-free-app-container .hex.hide {
  display: none;
}
.virus-free-app-container .extra-buttons {
  display: flex;
  justify-content: space-evenly;
}
.virus-free-app-container .body-container {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.virus-free-app-container .clickable,
.virus-free-app-container .model_close {
  cursor: pointer;
}
.virus-free-app-container .form-helper {
  color: grey;
  font-size: 11px;
  line-height: 14px !important;
  padding-left: 8px;
  padding-top: 4px;
}
.virus-free-app-container .direction_arrow.dashboard-btn {
  bottom: unset;
  left: unset;
  top: 60px;
  right: 79px;
  z-index: 10;
}
.virus-free-app-container .direction_arrow.dashboard-btn .arrow_img {
  width: 55px;
  height: 50px;
  background: #fca82f;
  color: white;
}
.virus-free-app-container .direction_arrow.dashboard-btn .arrow_img .mat-icon-button {
  line-height: unset;
}
.virus-free-app-container .direction_arrow.dashboard-btn .arrow_img .mat-button-wrapper {
  font-size: 8px;
}
.virus-free-app-container .success .start_hexa:after {
  background: #24e157;
}
.virus-free-app-container .success .currentHex {
  background: #24e157 !important;
}
.virus-free-app-container .success .currentHex .hexinner {
  margin: 3px;
}
.virus-free-app-container .score h1 {
  font-family: MTF Chubb, Fallback, sans-serif;
  font-size: 72px;
  line-height: 62px;
  color: #003c7b;
}
.virus-free-app-container .currentHex .hexinner {
  background: #ffd9a3;
}
.virus-free-app-container .play-icon.mat-icon {
  font-size: 46px;
  height: 46px;
  width: 46px;
}
.virus-free-app-container .score_box .completed {
  display: flex;
  align-items: center;
}
.virus-free-app-container .score_box .completed .restart-icon {
  height: 32px;
  width: 32px;
  font-size: 32px;
  margin-left: 0px;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
.virus-free-app-container .options.bible {
  position: relative;
}
.virus-free-app-container .danger_block .bottom_options .options.bible img.bible-icon {
  animation: shadow-pulse 1s infinite;
}
.virus-free-app-container .body-container {
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 426px) {
  .virus-free-app-container .body-container {
    width: 100% !important;
  }
}
.virus-free-app-container .route-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 16px;
}
.virus-free-app-container .ls_title {
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 12px;
}
.virus-free-app-container .level_boards {
  position: relative;
  padding-top: 16px;
}
.virus-free-app-container .leader_table .highlight {
  color: #003c7b;
  font-weight: 600;
}
.virus-free-app-container .pop_content {
  padding-bottom: 24px;
}
.virus-free-app-container .loader-container {
  display: flex;
  justify-content: center;
}
@media (min-width: 427px) {
  .virus-free-app-container {
    /* Add a thumb */
  }
  .virus-free-app-container .maze::-webkit-scrollbar,
.virus-free-app-container .route-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    /* or add it to the track */
    border-radius: 6px;
  }
  .virus-free-app-container .maze::-webkit-scrollbar-thumb,
.virus-free-app-container .route-container::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 6px;
  }
}
.virus-free-app-container .model_close:focus {
  outline: none;
}
.virus-free-app-container .app-progress-bar {
  position: absolute;
  z-index: 10;
}
.virus-free-app-container .optionHex.selectedHex:not(.currentHex) .hexinner {
  box-shadow: inset 5px 4px 12px #ffffff54;
}
.virus-free-app-container .hexinner {
  transition: 0.445s;
}
.virus-free-app-container .hex:not(.selectedHex) {
  background: #84a1c3;
}
.virus-free-app-container .hex[data-id=Goal1] .hexcontent,
.virus-free-app-container .hex[data-id=Goal2] .hexcontent,
.virus-free-app-container .hex[data-id=Goal3] .hexcontent,
.virus-free-app-container .hex[data-id=Goal4] .hexcontent,
.virus-free-app-container .hex[data-id=Goal5] .hexcontent,
.virus-free-app-container .hex[data-id=Goal6] .hexcontent {
  position: relative;
  color: white;
  padding: 0.25em;
  font-size: 1.1em;
  text-align: center;
  line-height: 1.2em;
  font-family: "Hobo Std", sans-serif;
}
.virus-free-app-container .hex[data-id=Goal1] .hexcontent.currentGoal::before,
.virus-free-app-container .hex[data-id=Goal2] .hexcontent.currentGoal::before,
.virus-free-app-container .hex[data-id=Goal3] .hexcontent.currentGoal::before,
.virus-free-app-container .hex[data-id=Goal4] .hexcontent.currentGoal::before,
.virus-free-app-container .hex[data-id=Goal5] .hexcontent.currentGoal::before,
.virus-free-app-container .hex[data-id=Goal6] .hexcontent.currentGoal::before {
  background: unset;
  box-shadow: unset;
}
.virus-free-app-container .hex[data-id=Goal1] .hexcontent::before,
.virus-free-app-container .hex[data-id=Goal2] .hexcontent::before,
.virus-free-app-container .hex[data-id=Goal3] .hexcontent::before,
.virus-free-app-container .hex[data-id=Goal4] .hexcontent::before,
.virus-free-app-container .hex[data-id=Goal5] .hexcontent::before,
.virus-free-app-container .hex[data-id=Goal6] .hexcontent::before {
  content: " ";
  position: absolute;
  width: 24px;
  height: 24px;
  background: #fca82f;
  z-index: -1;
  box-shadow: 0 0 5px 4px #fca82f;
  border-radius: 50%;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
}
.virus-free-app-container .mat-icon.img-icon {
  line-height: 12px !important;
}
.virus-free-app-container .mat-icon.img-icon img {
  height: 100%;
}
.virus-free-app-container .loading {
  position: relative;
}
.virus-free-app-container .loading::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: "";
  position: absolute;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: loading 1.5s infinite;
}
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
.virus-free-app-container .rules_img img {
  max-width: 80%;
}
.virus-free-app-container .opt_arrow img {
  height: 20px;
}
.virus-free-app-container .extra-buttons {
  margin-bottom: 70px;
}
.virus-free-app-container .extra-buttons button {
  margin: 0 8px;
}
.virus-free-app-container .warning {
  height: 130px;
}
.virus-free-app-container .admin-toolbar {
  background: #6066d0 !important;
}
.virus-free-app-container .app-button:not(:disabled) {
  background: #6066d0;
}
.virus-free-app-container .mat-card-header.with-action {
  justify-content: space-between;
}
.virus-free-app-container .policy_popup mat-dialog-container {
  max-width: 380px;
  border-radius: 15px !important;
  position: relative;
  overflow: inherit;
  height: 100%;
}
.virus-free-app-container .person {
  position: absolute;
  bottom: 0px;
}
.virus-free-app-container .bot {
  position: absolute;
  bottom: 0px;
  right: -5px;
}
.virus-free-app-container ::-webkit-scrollbar {
  width: 5px;
}
@media (min-width: 425px) {
  .virus-free-app-container .policy_popup {
    min-width: 360px;
    width: 360px;
  }
}
.virus-free-app-container .sb-group,
.virus-free-app-container .sb-button {
  display: inline-flex;
  align-items: flex-start;
}
.virus-free-app-container .sb-group {
  flex-wrap: wrap;
}
.virus-free-app-container .sb-button {
  margin: var(--sb-margin);
}
.virus-free-app-container .sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.virus-free-app-container .sb-wrapper .sb-icon,
.virus-free-app-container .sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.virus-free-app-container .sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.virus-free-app-container .sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.virus-free-app-container .sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.virus-free-app-container .sb-wrapper .sb-text {
  font-weight: bold;
}
.virus-free-app-container .sb-material-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-google {
  background-color: #db4437;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-line {
  background-color: #00b900;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-xing {
  background-color: #006567;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.virus-free-app-container .sb-material-dark .sb-wrapper:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text {
  padding-left: 0.3em;
}
.virus-free-app-container .sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  padding-right: 1em;
  padding-left: 0;
}
.virus-free-app-container .sb-group,
.virus-free-app-container .sb-button {
  display: inline-flex;
  align-items: flex-start;
}
.virus-free-app-container .sb-group {
  flex-wrap: wrap;
}
.virus-free-app-container .sb-button {
  margin: var(--sb-margin);
}
.virus-free-app-container .sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.virus-free-app-container .sb-wrapper .sb-icon,
.virus-free-app-container .sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.virus-free-app-container .sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.virus-free-app-container .sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.virus-free-app-container .sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.virus-free-app-container .sb-wrapper .sb-text {
  font-weight: bold;
}
.virus-free-app-container .sb-modern-light .sb-wrapper {
  color: var(--button-color) !important;
  background-color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6);
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-facebook {
  color: #4267B2;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-twitter {
  color: #00acee;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-google {
  color: #db4437;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-mix {
  color: #ff8226;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-line {
  color: #00b900;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-linkedin {
  color: #006fa6;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-pinterest {
  color: #bd081c;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-reddit {
  color: #ff4006;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-tumblr {
  color: #36465d;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-whatsapp {
  color: #25d366;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-messenger {
  color: #0080FF;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-telegram {
  color: #0088cc;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-xing {
  color: #006567;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-sms {
  color: #20c16c;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-email {
  color: #FF961C;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-viber {
  color: #665ca7;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-vk {
  color: #4C75A3;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-copy {
  color: #607D8B;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-print {
  color: #765AA2;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-expand {
  color: #FF6651;
}
.virus-free-app-container .sb-modern-light .sb-wrapper:active {
  box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9);
}
.virus-free-app-container .sb-modern-light .sb-wrapper .sb-icon {
  min-width: 2em;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-show-text {
  padding: 0;
}
.virus-free-app-container .sb-modern-light .sb-wrapper.sb-show-text .sb-icon {
  box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);
}
.virus-free-app-container .icon-btn {
  display: flex;
  align-items: center;
}
.virus-free-app-container .icon-btn .text {
  padding-left: 8px;
}
@media (max-width: 360px) {
  .virus-free-app-container button.level_btn {
    font-size: 11px;
  }
}

.virus-free-dialog.cdk-overlay-pane .mat-dialog-container {
  background-image: url(/assets/virus-free/model_man.png), url(/assets/virus-free/model_botimg.png);
  background-repeat: no-repeat;
  background-position: 8% bottom, right bottom;
  border-radius: 15px !important;
  position: relative;
  overflow: inherit;
  padding-top: 35px;
  max-width: 350px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .model_close {
  position: absolute;
  top: -25px;
  z-index: 9999;
  right: 8px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .pop_heading {
  color: #fca82f;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .pop_btext_sm {
  color: #003c7b;
  font-size: 13px;
  text-align: center;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .form_set label {
  display: block;
  line-height: initial;
  color: #003c7b;
  font-weight: 400;
  font-size: 15px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .form_set input {
  width: 100%;
  border: 1px solid #ececec;
  padding: 6px 12px;
  border-radius: 35px;
  color: #003c7b;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .form_set {
  margin-bottom: 15px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container button.pop_btn {
  background-color: #fca82f;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 8px 15px;
  min-width: 120px;
  background-image: url(assets/virus-free/pop_btn_bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .smily {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .model_head {
  text-align: center;
  margin-bottom: 25px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .model_button {
  text-align: center;
  margin-top: 30px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .clickable,
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .model_close {
  cursor: pointer;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .warning {
  height: 130px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .extra-buttons {
  display: flex;
  justify-content: space-evenly;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .extra-buttons {
  margin-bottom: 70px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .extra-buttons button {
  margin: 0 8px;
}
.virus-free-dialog.cdk-overlay-pane .mat-dialog-container .nocovid {
  position: absolute;
  top: -33px;
}

.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container {
  max-width: 380px;
  border-radius: 15px !important;
  position: relative;
  overflow: inherit;
  height: 100%;
}
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .model_close {
  position: absolute;
  top: -25px;
  z-index: 9999;
  right: 8px;
}
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .clickable,
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .model_close {
  cursor: pointer;
}
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .model_head {
  text-align: center;
  margin-bottom: 25px;
}
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .pop_heading {
  color: #fca82f;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .bot {
  position: absolute;
  bottom: 0px;
  right: -5px;
}
.virus-free-share-dialog.cdk-overlay-pane .mat-dialog-container .person {
  position: absolute;
  bottom: 0px;
}

.virus-free-setting-dialog.cdk-overlay-pane {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.virus-free-setting-dialog.cdk-overlay-pane .mat-dialog-container {
  border-radius: unset !important;
  background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
  position: relative;
}
.virus-free-setting-dialog.cdk-overlay-pane .mat-dialog-container button.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: white;
}
.virus-free-setting-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.virus-free-setting-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
  margin-left: 12px;
  border-bottom: 1px solid #1b3b73;
}
.virus-free-setting-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 38px;
}
.virus-free-setting-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  overflow: auto;
}

.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container {
  max-width: 380px;
  border-radius: 15px !important;
  position: relative;
  overflow: inherit;
  height: 100%;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .model_close {
  position: absolute;
  top: -25px;
  z-index: 9999;
  right: 8px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .clickable,
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .model_close {
  cursor: pointer;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .model_head {
  text-align: center;
  margin-bottom: 25px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .pop_heading {
  color: #fca82f;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .bot {
  position: absolute;
  bottom: 0px;
  right: -5px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .person {
  position: absolute;
  bottom: 0px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .pop_avatar_select .options h2 {
  color: #082252;
  font-size: 18px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .pop_avatar_select .options .avatar {
  text-align: center;
  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000036;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .model_button {
  text-align: center;
  margin-top: 30px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .options.selected .avatar {
  border: 3px solid #082252;
  position: relative;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container .options.selected .avatar:after {
  content: "";
  background: url(assets/virus-free/select.png);
  width: 25px;
  height: 25px;
  position: absolute;
  background-size: 100%;
  top: 13px;
  right: 3px;
}
.virus-free-slectImg-dialog.cdk-overlay-pane .mat-dialog-container button.pop_btn {
  background-color: #fca82f;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 8px 15px;
  min-width: 120px;
  background-image: url(assets/virus-free/pop_btn_bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.LeftDownHex {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -10px;
  margin-left: 1px;
}

.buttomLeftHex {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -25px;
  margin-left: 9px;
}

.setBackground {
  background: transparent !important;
}

.singleLeftButtomHex {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-left: 25px;
  margin-top: -16px;
}

.singleLeftTopHex {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-left: 25px;
  margin-top: 17px;
}

.singleRightTopHex {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 18px;
  margin-left: -22px;
}

.leftSideButtom {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -18px;
  margin-left: 18px;
}

.leftSidetop {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 15px;
  margin-left: -12px;
}

.topLeftfirst {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 34px;
  margin-left: 13px;
}

.topLeftSecond {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 10px;
  margin-left: 1px;
}

.rightButtomFirst {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -10px;
  margin-left: -17px;
}

.rightButtomSecond {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -30px;
  margin-left: 2px;
}

.singleRightDownHex {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -17px;
  margin-left: -25px;
}

.topRightFirst {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 10px;
  margin-left: -15px;
}

.topRightSecond {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 35px;
}

.rightSideFirst {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -14px;
  margin-left: -26px;
}

.rightSideSecond {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: 17px;
  margin-left: -14px;
}

.singleHexDown {
  font-size: 0.7em;
  color: gray;
  line-height: 1.2em;
  margin-top: -30px;
  margin-left: 0px;
}

.profile-image-app-dialog.cdk-overlay-pane {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container {
  border-radius: unset !important;
  background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
  position: relative;
}
.profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container button.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: white;
}
.profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
  margin-left: 12px;
  border-bottom: 1px solid #1b3b73;
}
.profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 38px;
}
.profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  overflow: auto;
}

@media only screen and (min-width: 576px) {
  .profile-image-app-dialog.cdk-overlay-pane {
    max-width: 63% !important;
    max-height: 85% !important;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container {
    border-radius: unset !important;
    background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
    position: relative;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container button.close {
    position: absolute;
    right: 8px;
    top: 12px;
    color: white;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
    margin-left: 12px;
    border-bottom: 1px solid #1b3b73;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
    height: 38px;
    width: 38px;
    line-height: 38px;
    font-size: 38px;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
    height: calc(100% - 85px);
    min-height: calc(100% - 85px);
    overflow: auto;
  }
}
@media only screen and (min-width: 800px) {
  .profile-image-app-dialog.cdk-overlay-pane {
    max-width: 40% !important;
    max-height: 90% !important;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container {
    border-radius: unset !important;
    background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
    position: relative;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container button.close {
    position: absolute;
    right: 8px;
    top: 12px;
    color: white;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
    margin-left: 12px;
    border-bottom: 1px solid #1b3b73;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
    height: 38px;
    width: 38px;
    line-height: 38px;
    font-size: 38px;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
    height: calc(100% - 85px);
    min-height: calc(100% - 85px);
    overflow: auto;
  }
}
@media only screen and (min-width: 1000px) {
  .profile-image-app-dialog.cdk-overlay-pane {
    max-width: 32% !important;
    max-height: 90% !important;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container {
    border-radius: unset !important;
    background: linear-gradient(180deg, #91a0bb 0%, #1b3b73 100%);
    position: relative;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container button.close {
    position: absolute;
    right: 8px;
    top: 12px;
    color: white;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title {
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .dialog-title-text {
    margin-left: 12px;
    border-bottom: 1px solid #1b3b73;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-title .mat-icon {
    height: 38px;
    width: 38px;
    line-height: 38px;
    font-size: 38px;
  }
  .profile-image-app-dialog.cdk-overlay-pane .mat-dialog-container .mat-dialog-content {
    height: calc(100% - 85px);
    min-height: calc(100% - 85px);
    overflow: auto;
  }
}
.dot {
  height: 16px;
  width: 16px;
  background-color: #2ead1d;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: -5px;
  border: 1px solid;
}

.table_form .form-control {
  border: 0px solid #795548;
  background-color: transparent;
}
.table_form .form-control .mat-select-value {
  color: white;
  font-size: 13px;
}
.table_form .form-control .mat-select-arrow {
  color: white;
}
.table_form .form-control:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

#leaderboard-table .mat-sort-header-content {
  width: 100%;
}
#leaderboard-table .mat-sort-header-arrow {
  color: white;
}
#leaderboard-table .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

#quiz_toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #795548 !important;
}
#quiz_toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(121, 85, 72, 0.67) !important;
}

#qd_slider .ngx-slider .ngx-slider-pointer {
  background-color: #795548;
}
#qd_slider .ngx-slider .ngx-slider-selection {
  background-color: #795548;
}
#qd_slider .ngx-slider-active::after {
  background-color: #a5a5a5;
}

.center_align {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader_download .mat-progress-spinner circle,
#loader_download .mat-spinner circle {
  stroke: #ffffff !important;
}

#accountTab .mat-tab-body-content {
  background: 0% 0% no-repeat padding-box padding-box rgba(0, 0, 0, 0.45);
  border: 2px solid rgba(255, 175, 82, 0.294);
  border-radius: 30px;
  opacity: 1;
  backdrop-filter: blur(15px);
  padding-top: 20px;
  padding-bottom: 20px;
}
#accountTab .mat-tab-label-content {
  font-family: Poppins;
}
#accountTab .mat-tab-list {
  border-bottom: 0px solid #6c431c;
  margin-left: 25px;
}
#accountTab .mat-tab-label {
  clip-path: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
#accountTab .firstTab {
  border-top-left-radius: 18px;
}
#accountTab .secondTab {
  border-top-right-radius: 18px;
}

.textTransform {
  position: absolute;
  top: 85px;
  margin-left: -19px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  font-size: 12px;
  line-height: 15px;
  width: 80px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sieve-matrix-container {
  position: absolute;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
}
.sieve-matrix-container .sieve-list {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Let's get this party started */
  /* Track */
  /* Handle */
}
.sieve-matrix-container .sieve-list:hover::-webkit-scrollbar {
  display: unset;
}
.sieve-matrix-container .sieve-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  display: none;
}
.sieve-matrix-container .sieve-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.sieve-matrix-container .sieve-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(206, 206, 206, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.sieve-matrix-container .sieve-list::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(206, 206, 206, 0.8);
}
.sieve-matrix-container .sieve-list .sieve-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 205px;
  height: 205px;
}
.sieve-matrix-container .sieve-list .sieve-row {
  display: flex;
}
.sieve-matrix-container .sieve-list .sieve-row .sieve {
  flex: 1 1 auto;
}
.sieve-matrix-container .sieve-list .sieve {
  min-height: 16px;
  min-width: 16px;
  width: 100%;
  border-radius: 3px;
  box-shadow: inset -3px 3px 5px #573222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #555454;
  transition: 255ms;
  cursor: pointer;
  background-color: white;
  margin: 4px;
  position: relative;
}
.sieve-matrix-container .sieve-list .sieve.disabled {
  box-shadow: 0px 0px 0px #573222;
  background-color: transparent;
}
.sieve-matrix-container .sieve-list .sieve .sieve-grain-img {
  width: 24px;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content .timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 71px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1000;
  background: #ffffffbd;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content:not(.answered) {
  cursor: pointer;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered {
  position: relative;
  flex-direction: column;
  position: relative;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered img.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-bottom: 4px;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered .name {
  font-size: 8px;
  line-height: 11px;
  font-weight: 600;
  color: #555454;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered .answer-badge {
  position: absolute;
  color: white;
  border-radius: 50%;
  font-size: 19px;
  height: 21px;
  width: 21px;
  padding-left: 1px;
  padding-top: 1px;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered .answer-badge.correct {
  background: #3bb54a;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered .answer-badge.wrong {
  background: #ff0000;
}
.sieve-matrix-container .sieve-list .sieve .sieve-content.answered .answer-badge.warning {
  background: #f4e100;
}
.sieve-matrix-container .sieve-list .sieve .sieve-value {
  padding-bottom: 4px;
}
.sieve-matrix-container .sieve-list .sieve a.select-topic {
  position: absolute;
  cursor: pointer;
  top: -115px;
}
.sieve-matrix-container .sieve-list .sieve a.select-level {
  position: absolute;
  left: -36px;
}
.sieve-matrix-container .sieve-list .sieve a.select-level .select-level-content {
  position: relative;
}
.sieve-matrix-container .sieve-list .sieve a.select-level .select-level-content .levelTransform {
  position: absolute;
  right: 11px;
  top: 8px;
  font-size: 12px;
  line-height: 15px;
}

.btn_flex {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.sieve_background {
  height: 300px;
  width: 300px;
}

.bottom_align {
  position: fixed;
  bottom: 35px;
  left: 33%;
  right: 33%;
}

@media only screen and (max-width: 375px) {
  .sieve-game-container {
    height: 325px !important;
  }

  .play_sieve_area .sieve-game-container {
    height: 280px !important;
  }
  .play_sieve_area .sieve_background {
    height: 260px;
    width: 260px;
  }
  .play_sieve_area .sieve-matrix-container {
    width: 260px;
  }
  .play_sieve_area .sieve-matrix-container .sieve-list {
    height: 260px;
  }
  .play_sieve_area .sieve-matrix-container .sieve-list .sieve-list-container {
    width: 185px;
    height: 185px;
  }
  .play_sieve_area .sieve-game-container {
    height: 285px !important;
  }
}
.wall-leap #wall-leap .mat-tab-labels {
  gap: 5px;
}
.wall-leap #wall-leap .mat-tab-label {
  background-color: #ffa19e;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
}
.wall-leap #wall-leap .mat-tab-label .mat-tab-label-content {
  font-size: 16px;
  font-weight: 500;
  color: black;
}
.wall-leap #wall-leap .mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
.wall-leap #wall-leap .mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
  opacity: 1;
}
.wall-leap button {
  background-color: #d99593;
  color: white;
  border-radius: 8px;
  border: 0;
  border-color: white;
}

#tableSort .mat-sort-header-container {
  justify-content: center;
}

#countdown {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  transform: translate(-50%, -50%);
  transition: 1s;
  font-size: 2vw;
  opacity: 50%;
  color: black;
}

.custom_scroll_run::-webkit-scrollbar {
  width: 4px;
}

.custom_scroll_run::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}

.custom_scroll_run::-webkit-scrollbar-thumb {
  background-color: #6c431c;
  outline: 1px solid #6c431c;
}