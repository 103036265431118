button:focus {
    outline: none !important;
}

button.rounded {
    border-radius: 23px !important;
}

button.outline {
    border: 2px solid;
    overflow: hidden;

    &.mat-primary {
        border-color: $primary-color !important;
        color: $primary-color !important;
    }
}

.mat-raised-button:not(.fit),
.mat-button:not(.fit) {
    min-width: 140px !important;
}

.mat-raised-button.mat-primary:not(:disabled) {
    background-color: $primary-color !important;
    .mat-button-wrapper {
        color: white !important;
    }
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    background-color: $accent-color !important;
}

.mat-icon-button.social {
    height: 75px;
    width: 75px;
    .mat-icon {
        height: 75px;
        width: 75px;
        line-height: 60px;
        img {
            height: 75px;
            width: 75px;
        }
    }
}

.mat-mini-fab.direction-nav:not(:disabled) {
    background: #555454;
    color: white;
}


@media screen and (min-width: 320px) {
    .mat-raised-button:not(.fit),
    .mat-button:not(.fit) {
        min-width: 120px !important;
    }
}
