.projector-mode-options.mat-selection-list {
    .mat-list-option {
        height: 116px;
        background-color: #764128;
        margin-bottom: 16px;
        box-shadow: inset 19px 19px 80px #532e1c, inset -19px -19px 80px #995534;
        border-radius: 25px;
        overflow: hidden;
        transition: 445ms;

        &[aria-selected="true"] {
            background-color: #83c12e;
            box-shadow: inset 24px 24px 78px #567f1e, inset -24px -24px 78px #b0ff3e;
        }

        .mat-pseudo-checkbox {
            display: none;
        }

        .mat-list-text {
            padding-right: 0px !important;
            text-align: center;
            color: white;
            font-family: "Bangers", cursive;
            font-size: 34px;
            line-height: 39px;
        }
    }
}
