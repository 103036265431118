$icon-size: 22px;
.stepper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    .step-connector {
        height: 8px;
        background: #d8e8f6;
        flex: 1 1;
        margin: 0 -26px;
        margin-top: 28px;

        &.active {
            background: $primary-color;
        }

        &:first-child {
            margin-left: unset !important;
            max-width: 26px !important;
            border-radius: 5px 0 0 5px;
            background: $primary-color;
        }

        &:last-child {
            margin-right: unset !important;
            max-width: 26px !important;
            border-radius: 0 5px 5px 0;
        }
    }

    .step {
        text-align: center;
        color: $primary-color;
        padding: 0px 26px;
        cursor: pointer;
        width: 114px;

        &.error {
            .stepper-icon-container {
                border-color: #ff4747d8 !important;

                .mat-icon {
                    color: #ff4747d8 !important;
                }
            }
        }

        &.active {
            .stepper-icon-container {
                border: 4px solid $primary-color;
            }
        }

        &.completed {
            .stepper-icon-container {
                background: $primary-color;

                .icon {
                    .mat-icon {
                        color: white !important;
                    }
                }
            }
        }

        .stepper-icon-container {
            padding: 16px;
            border-radius: 50%;
            border: 4px solid #ffffff;
            background: #f2f9ff;
            .icon {
                line-height: 10px;
                width: 22px;
                color: $primary-color;
                .mat-icon {
                    height: $icon-size;
                    width: $icon-size;
                    line-height: $icon-size;
                    font-size: $icon-size;
                }
            }
        }

        .stepper-label {
            padding-top: 8px;
            font-size: 1rem;
            line-height: 1.2rem;
        }
    }
}
