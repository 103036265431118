.mat-slide-toggle {
    &.mat-slide-toggle.mat-checked {
        .mat-slide-toggle-bar {
            background-color: rgba(231, 231, 231, 0.54);
        }

        .mat-slide-toggle-thumb-container {
            transform: translate3d(22px, 0, 0);
        }

        .mat-slide-toggle-thumb {
            background-color: #8bc34a;
        }
    }

    .mat-slide-toggle-thumb-container {
        top: 1.5px;
        left: 3px;
    }

    .mat-slide-toggle-thumb {
        background-color: #8bc34a;
    }

    .mat-slide-toggle-bar {
        height: 26px;
        width: 50px;
        border-radius: 15px;
        background: white;
        border: 1px solid #a25833;
    }
}

.slide-toggle-container {
    display: flex;
    align-items: center;

    label {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 17px;
        font-weight: 800;

        &:not(.selected) {
            color: #b9b9b9;
        }
    }

    input.form-control {
        width: 70px !important;
        text-align: center;
    }
}
