$primary-color: #795548;
$accent-color: #83c12e;

/* You can add global styles to this file, and also import other style files */
@import "./sieve/layout";
@import "./sieve/stepper";
@import "./sieve/card";
@import "./sieve/form/input";
@import "./sieve/form/checkbox";
@import "./sieve/form/slide-toggle";
@import "./sieve/table";
@import "./sieve/button";
@import "./sieve/chips";
@import "./sieve/game-projector-mode";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.sieve-app-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.primary-color {
  color: $primary-color;
}

.form-action {
  margin-top: 24px;
}

.loader-container .mat-spinner circle {
  stroke: $primary-color !important;
}

.or-with-divider {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 18px;
  position: relative;
  color: #b9b9b9;

  // &::after {
  //   content: " ";
  //   position: absolute;
  //   border: 1px solid #dddfe1;
  //   width: calc(50% - 20px);
  //   left: 0px;
  //   top: 9px;
  //   height: 0px;
  // }

  // &::before {
  //   content: " ";
  //   position: absolute;
  //   border: 1px solid #dddfe1;
  //   width: calc(50% - 20px);
  //   right: 0px;
  //   top: 9px;
  //   height: 0px;
  // }
}

.social-action {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 0 32px 0;

  .social {
    margin: 0 16px;
  }
}

.slider-container {
  padding-top: 26px;
  margin-bottom: 12px;
  position: relative;

  .form-control {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
    color: $primary-color;
  }

  .mat-error {
    position: absolute;
    top: 100%;
    left: 12px;
  }
}

.mat-slider {
  width: 100%;
}

.title {
  // padding-bottom: 24px;
  & > h3 {
    font-weight: 800;
    text-transform: uppercase;
  }
}

.logo-container {
  padding: 16px;
  display: flex;
  justify-content: center;

  .logo-alt {
    width: 160px;
  }
}

.subtitle {
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 8px;
  font-weight: bold;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tutorial-btn {
  padding-top: 6px !important;
  line-height: 28px !important;

  .mat-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

button.back-button {
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 10;
}

.p-v-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.forgot-password-dialog {
  width: 350px;
}

.with-loader .mat-dialog-container {
  position: relative;

  .mat-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

h3.title-text {
  font-weight: bold;
}

.mat-standard-chip.mat-chip.sieve-chip {
  padding: 7px 12px 7px 4px;
  color: #794023 !important;
  background: #f0e7e3 !important;
  font-weight: 800;
  margin-right: 8px;
  margin-bottom: 8px;

}

.mat-standard-chip.mat-chip.my_preset {
  color: #215f60 !important;
  background: #baeac7 !important;
}

@media only screen and (min-width: 768px) {
  .create-game-container,
  .lobby-container,
  .play-area-container,
  .responsive-container {
    height: 100%;
    .action.desktop {
      display: block !important;
    }

    .header {
      display: flex;
      margin-bottom: 24px;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: unset !important;

        & > h3 {
          margin: unset;
          font-size: 30px;
          line-height: 41px;
          font-weight: 800;
          color: #71422c;
          text-transform: uppercase;
        }
      }

      .logo-container,
      .title {
        width: 33%;
      }
    }
  }

  /*
    create game 
  */
  .create-stepper-container {
    .mat-horizontal-content-container {
      display: flex;
      height: 100%;

      .mat-horizontal-stepper-content {
        width: 50%;
        transform: unset !important;
        visibility: visible !important;
        min-height: 100% !important;
        height: unset !important;
        padding: 0 18px;

        &:not(:last-child) {
          border-right: 2px solid #fbca7b;
        }

        .title,
        .action {
          display: none;
        }
      }
    }
  }

  /*
  Play area
  */

  .play-area-container {
    .sieve-matrix {
      max-width: 768px;
      width: 100%;
      margin: 0 auto;
    }

    // .play-container {
    //   max-height: calc(100% - 258px) !important;
    // }

    .question-container {
      width: 100%;
      max-width: 546px;
      margin: 0 auto;
    }
  }
}
