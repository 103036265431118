.search-form {
    position: relative;

    .mat-icon.search-icon {
        position: absolute;
        right: 16px;
        top: 8px;
        color: $primary-color;
    }
}

.form-control {
    border: 1px solid $primary-color;
    border-radius: 30px;
    width: 100%;

    &[readonly] {
        background-color: #fff;

        &:focus {
            box-shadow: unset !important;
        }
    }

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem rgb(121 85 72 / 25%);
        outline: 0;
    }

    &.search {
        border: 2px solid $primary-color;
        border-radius: 29px;
        padding-right: 46px;
        padding-left: 24px;

        &:focus {
            box-shadow: unset !important;
        }
    }

    &input,
    &.mat-select {
        height: 38px;
    }
}

.form-group {
    position: relative;

    label {
        font-size: 12px;
        line-height: 17px;
        font-weight: 600;
        color: $primary-color;
        padding-left: 12px;
        margin-bottom: 0.1rem;
    }

    &.verticle-align {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-control {
            max-width: 58px;
        }
    }

    &.with-suffix {
        position: relative;

        .form-control {
            padding-right: 36px;
        }

        .suffix {
            color: $primary-color;
            position: absolute;
            right: 8px;
            bottom: 7px;
        }
    }

    &.with-prefix {
        position: relative;

        .form-control {
            padding-right: 36px;
        }

        .prefix {
            color: $primary-color;
            position: absolute;
            left: 8px;
            bottom: 7px;
        }
    }

    .form-control-group {
        &.contact-number {
            position: relative;
            padding-left: 104px;
            .country-code {
                position: absolute;
                width: 94px;
                left: 0;
                top: 0;
            }
        }
    }

    .mat-error {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        left: 12px;
        top: calc(100%);
    }
}

.with-icon {
    position: relative;

    .form-control {
        padding-right: 20px;
    }

    .suffix {
        color: $primary-color;
        position: absolute;
        right: 5px;
        bottom: 7px;
    }
}

.with-error {
    position: relative;
    margin-bottom: 1rem;

    .mat-error {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        left: 0;
        top: calc(100%);
    }
}
