table {
    width: 100% !important;

    tr:focus {
        outline: unset !important;
    }
}
.outlined-table {
    th.mat-header-cell {
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #136cb6;
        padding: 0 8px;
    }

    tr.mat-row {
        height: 66px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 2px;
        padding-right: 2px;

        &.selectable {
            cursor: pointer;

            &:hover .cell-content {
                border-color: $primary-color;
            }
        }

        .cell-content {
            background: #f7faff;
            height: 50px;
            display: flex;
            align-items: center;
            transition: border-color 155ms;
            border-bottom: 1px solid #f7faff;
            border-top: 1px solid #f7faff;
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
            color: #000000cb;
            justify-content: center;
            padding: 0 8px;
        }
    }

    td.mat-cell {
        link {
            color: #ff000094;
        }

        &:first-of-type .cell-content {
            padding-left: 24px;
            border-left: 1px solid #f7faff;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-of-type .cell-content {
            padding-right: 24px;
            border-right: 1px solid #f7faff;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    td.mat-cell:first-of-type,
    td.mat-cell:last-of-type {
        padding: unset;
    }

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        border: none;
        text-align: center;

        &:focus {
            outline: unset !important;
        }
    }
}

.question-column {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-no-data {
    padding-top: 36px;
}
