.form-group {
    position: relative;

    label {
        font-size: 12px;
        line-height: 17px;
        font-weight: 600;
        color: white;
        padding-left: 12px;
        margin-bottom: 0.1rem;
    }

    .form-control {
        border-radius: 40px;

        &:focus {
            border-color: unset;
            outline: 0;
            box-shadow: unset;
        }
    }

    .mat-hint {
        color: white;
        padding-left: 16px;
    }

    &.verticle-align {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-control {
            max-width: 58px;
        }
    }

    &.with-suffix {
        position: relative;
        &.toggle {
            &.active {
                .form-control {
                    color: #6de354 !important;
                }

                .suffix {
                    background-color: #6de354;
                }
            }

            .suffix {
                color: white;
                background-color: #484848;
                border-radius: 50%;
                padding: 3px;
                font-size: 18px;
            }
        }

        .form-control {
            padding-right: 36px;
        }

        .suffix {
            color: $primary-color;
            position: absolute;
            right: 8px;
            bottom: 7px;
        }
    }

    &.with-prefix {
        position: relative;

        .form-control {
            padding-right: 36px;
        }

        .prefix {
            color: $primary-color;
            position: absolute;
            left: 8px;
            bottom: 7px;
        }
    }

    .form-control-group {
        &.contact-number {
            position: relative;
            padding-left: 104px;
            .country-code {
                position: absolute;
                width: 94px;
                left: 0;
                top: 0;
            }
        }
    }

    .mat-error {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        left: 12px;
        top: calc(100%);
    }
}

.with-icon {
    position: relative;

    .form-control {
        padding-right: 20px;
    }

    .suffix {
        color: $primary-color;
        position: absolute;
        right: 5px;
        bottom: 7px;
    }
}

.with-error {
    position: relative;
    margin-bottom: 1rem;

    .mat-error {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        left: 0;
        top: calc(100%);
    }
}
