button:focus {
    outline: none !important;
}

button.rounded {
    border-radius: 23px !important;
    padding: 0 24px;

    &.loading {
        padding-right: 36px !important;
    }
}

button.outline {
    border: 2px solid;
    overflow: hidden;

    &.mat-primary {
        border-color: $primary-color;
        color: $primary-color;
    }
}

.mat-raised-button:not(.fit),
.mat-button:not(.fit) {
    min-width: 140px !important;
}

.mat-raised-button.mat-primary:not(:disabled) {
    background-color: $primary-color;

    .mat-button-wrapper {
        color: #3b1d00;
    }
}

.button-loader {
    position: absolute !important;
    right: 5px;
    top: 7px;
}

.mat-icon-button.social {
    .mat-icon {
        height: 40px;
        width: 40px;
        line-height: 40px;
    }
}

@media screen and (min-width: 320px) {
    .mat-raised-button:not(.fit),
    .mat-button:not(.fit) {
        min-width: 120px !important;
    }
}
