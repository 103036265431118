.checkbox-outlined {
    border: 2px solid #dddfe1;
    border-radius: 6px;
    padding: 6px;
    display: flex;
    align-items: center;
    height: 42px !important;

    .mat-list-item-content {
        height: 40px !important;
        padding: 0 10px !important;
    }

    .mat-icon {
        height: 18px;
        width: 18px;
        line-height: 18px;
        font-size: 18px;
        margin-right: 10px;
        margin-left: 4px;
        color: $primary-color;
    }

    .text {
        white-space: nowrap;
        padding-left: 8px !important;
    }

    .mat-list-text {
        flex-direction: row !important;
        align-items: center !important;
        padding-right: 8px !important;
    }

    .mat-list-item-ripple {
        display: none;
    }

    .mat-checkbox-inner-container {
        margin-left: 16px;
        margin-right: 4px;
    }

    .mat-checkbox-layout {
        flex-direction: row-reverse !important;
        margin-bottom: 1px !important;
    }
}

.mat-selection-list.row {
    display: flex !important;
}

.mat-checkbox {
    .mat-checkbox-inner-container,
    .mat-checkbox-frame,
    .mat-checkbox-background {
        height: 20px;
        width: 20px;
    }

    &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
        background-color: #136cb6;
    }

    .mat-checkbox-frame {
        border: unset !important;
        background-color: #dfdfdf;
    }
}

.mat-pseudo-checkbox {
    background-color: #dfdfdf !important;
    border: unset !important;
    height: 20px !important;
    width: 20px !important;
}

.mat-pseudo-checkbox-checked::after {
    top: 5.4px !important;
    left: 4px !important;
    width: 10px !important;
    height: 5px !important;
}

.mat-primary .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: #136cb6 !important;

    &.mat-pseudo-checkbox-checked::after {
        top: 5.4px !important;
        left: 4px !important;
        width: 10px !important;
        height: 5px !important;
    }
}

.mat-accent .mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: #83c12e !important;
}

.mat-checkbox.invert {
    .mat-checkbox-layout {
        flex-direction: row-reverse;

        .mat-checkbox-inner-container {
            margin-right: 0px;
            margin-left: 8px;
        }
    }
}
